import moment, { Moment } from "moment";
import React, { Component } from "react";
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { FaFacebook, FaSave, FaTimes, FaTwitter } from "react-icons/fa";
import { toast } from "react-toastify";
import AppContext from "../AppContext";
import { Business, PostQueue } from "../models";
import { EventProvider } from "../providers/EventProvider";
import { Checkbox } from "./inputs/Checkbox";
import { DateTime } from "./inputs/DateTime";
import { Dropdown } from "./inputs/Dropdown";
import { Textarea } from "./inputs/Textarea";
import COLORS from "./static/Colors";

interface Props {
  show: boolean,
  eventid?: string,
  allowFacebook: boolean,
  allowTwitter: boolean,
  onClose: (queue: PostQueue[]) => void
}

interface State {
  twitterQueue: boolean
  facebookQueue: boolean,
  queueInviteLink: boolean,
  postContent: string,
  savingQueue: boolean,
  postHoursBefore: { label: string, value: number },
  postDate: Moment
}

export class PostQueueModal extends Component<Props, State> {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      twitterQueue: this.props.allowTwitter,
      facebookQueue: this.props.allowFacebook,
      queueInviteLink: false,
      postContent: '',
      savingQueue: false,
      postHoursBefore: { label: 'Post Immediately', value: -1 },
      postDate: moment()
    }
  }

  getMaxLength = () => {
    return this.state.twitterQueue ? (this.state.queueInviteLink ? 225 : 280) : 2000;
  }

  handleQueueEventModalClose = (queues: PostQueue[] = null) => {
    this.setState({ postContent: '', postHoursBefore: { label: 'Post Immediately', value: -1 }, postDate: moment(), twitterQueue: this.props.allowTwitter, facebookQueue: this.props.allowFacebook });
    this.props.onClose(queues);
  }

  handlePostQueueSave = () => {
    const maxLength = this.getMaxLength();

    if (!this.state.facebookQueue && !this.state.twitterQueue) {
      toast.error('Please select at least one platform for the post.');
      return;
    }

    if (this.state.postContent.trim() === '') {
      toast.error('Please enter a message for the post.');
      return;
    }

    if (this.state.postContent.length > maxLength) {
      toast.error('The post is too long with the options you have selected.');
      return;
    }

    this.setState({ savingQueue: true });

    let proms: Promise<PostQueue>[] = [];

    if (this.state.facebookQueue)
      proms.push(EventProvider.saveUpdatePostQueue(this.context.user.businessid, this.state.postContent, this.props.eventid, 'facebook', this.state.queueInviteLink, null, this.state.postHoursBefore.value, this.state.postDate.toDate() ));

    if (this.state.twitterQueue)
      proms.push(EventProvider.saveUpdatePostQueue(this.context.user.businessid, this.state.postContent, this.props.eventid, 'twitter', this.state.queueInviteLink, null, this.state.postHoursBefore.value, this.state.postDate.toDate() ));

    Promise.all(proms).then((results) => {
      this.handleQueueEventModalClose(results);
      toast.success('Post(s) will be submitted at the scheduled time.');
    }).catch((e) => {
      console.log(e);
      toast.error('One or more posts failed to schedule. It is recommended to check the dashboard before trying to schedule them again.');
    }).finally(() => {
      this.setState({ savingQueue: false });
    });
  }

  render() {
    return (
      <Modal centered show={this.props.show} onHide={this.props.onClose} aria-labelledby='contained-modal-title-vcenter'>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Schedule Social Media Post</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Toggle which platform to post on by clicking their logos.</p>
          <Row>
            <Col className='pointer' onClick={() => { if (this.props.allowFacebook) this.setState({ facebookQueue: !this.state.facebookQueue }) }}>
              <FaFacebook className='mr-3' color={this.state.facebookQueue ? COLORS.FACEBOOK : COLORS.GRAY} size={30} />
            Facebook
          </Col>
            <Col className='pointer' onClick={() => { if (this.props.allowTwitter) this.setState({ twitterQueue: !this.state.twitterQueue }) }} >
              <FaTwitter className='mr-3' color={this.state.twitterQueue ? COLORS.TWITTER : COLORS.GRAY} size={30} />
            Twitter
          </Col>
          </Row>
          <br />
          <Textarea height={150} label={`Post Content (${this.state.postContent.length}/${this.getMaxLength()})`} placeholder='Write post here...' error={this.state.postContent.length > this.getMaxLength()} maxLength={this.getMaxLength()} value={this.state.postContent} onTextChange={(val) => this.setState({ postContent: val })} />
          {this.props.eventid && <>
            <Dropdown label='When should this be posted?' value={this.state.postHoursBefore} options={[
              { label: 'Post Immediately', value: -1 },
              { label: 'When Event Starts', value: 0 },
              { label: '1 Hour Before Event', value: 1 },
              { label: '6 Hours Before Event', value: 6 },
              { label: '1 Day Before Event', value: 24 },
              { label: '1 Week Before Event', value: 168 }
            ]} onChange={(val) => this.setState({ postHoursBefore: val })} />
            <Checkbox label='Include a link to the event?' value={this.state.queueInviteLink} onChange={(val) => this.setState({ queueInviteLink: val })} />
          </>}
          {!this.props.eventid && <DateTime
            maxTime={moment().endOf('day')}
            minTime={moment().isSame(this.state.postDate, 'day') ? moment().startOf('hour') : moment().startOf('day')}
            maxDate={moment().add(1, 'year')}
            minDate={moment()}
            onChange={(val) => this.setState({ postDate: val })}
            value={this.state.postDate}
            label='Post On Date' />}
        </Modal.Body>

        <Modal.Footer>
          <Button variant='secondary' disabled={this.state.savingQueue} onClick={() => this.handleQueueEventModalClose()}><FaTimes size={20} /> Cancel</Button>
          <Button disabled={this.state.savingQueue || (!this.state.facebookQueue && !this.state.twitterQueue) || this.state.postContent === ''} onClick={this.handlePostQueueSave}>{this.state.savingQueue ? <Spinner as='span' animation='border' size='sm' /> : <FaSave size={20} />} Submit</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}