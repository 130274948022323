import React, { Component } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment, { Moment } from 'moment'

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Card, Button, Row, Col, Spinner } from "react-bootstrap";
import { MdAddAlarm, MdBluetoothDisabled } from "react-icons/md";
import { GenericModal } from "../../shared/GenericModal";
import { FaTimes, FaSave, FaTrashAlt, FaFacebook, FaTwitter } from "react-icons/fa";
import { Textbox } from "../../shared/inputs/Textbox";
import { Textarea } from '../../shared/inputs/Textarea';
import { DateTime } from "../../shared/inputs/DateTime";
import { Dropdown } from "../../shared/inputs/Dropdown";
import { Location, Event, Business, EventStat, PostQueue, SUBSCRIPTION_TIERS, AggregateStat } from "../../models";
import { UserProvider } from "../../providers/UserProvider";
import AppContext from "../../AppContext";
import { toast } from "react-toastify";
import { differenceInHours } from 'date-fns';

import './events.css';
import { EventProvider } from "../../providers/EventProvider";
import { LocationProvider } from "../../providers/LocationProvider";
import { Checkbox } from "../../shared/inputs/Checkbox";
import { ConfirmationAlert } from "../../shared/ConfirmationAlert";
import { RouteComponentProps } from "react-router";
import { Number } from "../../shared/inputs/Number";
import { StatisticsProvider } from "../../providers/StatisticsProvider";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { FacebookProvider } from "../../providers/FacebookProvider";
import COLORS from "../../shared/static/Colors";
import { PostQueueModal } from "../../shared/PostQueueModal";
import CurrencyInput from "../../shared/inputs/CurrenyInput";
import Address from "../../shared/Address";
import { Bar, BarChart, Legend, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const localizer = momentLocalizer(moment)

interface Props extends RouteComponentProps { }

interface State {
  averageInteractions: number,
  averageViews: number,
  averageAttendees: number,
  showEventModal: boolean,
  showEventStatModal: boolean,
  showPostQueueModal: boolean,
  eventStat: { event: Event, data: { stat: string, average: number, event: number }[] },
  eventid: string,
  chatid: string,
  startDate: Moment,
  endDate: Moment,
  name: string,
  description: string,
  masksRequired: boolean,
  coverCharge: number,
  createChat: boolean,
  location: Location,
  // customLocation: Location,
  selectedLocationOption: object,
  // locations: Location[],
  events: Event[],
  business: Business,
  savingEvent: boolean,
  // useCustomLocation: boolean,
  loadingEventStats: boolean,
  hasFacebookToken: boolean,
  hasTwitterToken: boolean,
  queueEventID: string,
  isLoading: boolean
}

export class Events extends Component<Props, State> {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      averageAttendees: 0,
      averageInteractions: 0,
      averageViews: 0,
      showEventModal: false,
      showEventStatModal: false,
      showPostQueueModal: false,
      eventStat: null,
      eventid: null,
      chatid: null,
      startDate: this.getMinStartDate(),
      endDate: this.getMinEndDate(this.getMinStartDate()),
      name: '',
      description: '',
      masksRequired: false,
      coverCharge: null,
      location: null,
      // customLocation: { status: 'active' } as Location,
      createChat: false,
      selectedLocationOption: null,
      // locations: [],
      events: [],
      business: null,
      savingEvent: false,
      // useCustomLocation: false,
      loadingEventStats: false,
      hasFacebookToken: false,
      hasTwitterToken: false,
      queueEventID: null,
      isLoading: true
    };
  }

  componentDidMount() {
    if (this.context.user) {
      // const locations = LocationProvider.getLocations(this.context.user.businessid).then((locations) => {
      //   return new Promise<void>((resolve) => {
      //     this.setState({ locations, useCustomLocation: locations.length < 1 ? true : false }, resolve);
      //   });
      // }).catch((err) => {
      //   toast.error('Failed to get locations.');
      // });

      const events = EventProvider.getEvents(this.context.user.businessid).then((events) => {
        return new Promise<void>((resolve) => {
          this.setState({ events }, resolve);
        });
      }).catch((err) => {
        toast.error('Failed to load existing events.');
      });

      const business = UserProvider.getBusiness(this.context.user.businessid).then((business) => {
        return new Promise<void>((resolve) => {
          this.setState({ createChat: business.settings?.defaultAllowChat, business }, resolve);
        });
      });

      const facebook = UserProvider.getFacebookToken(this.context.user.businessid).then((res) => {
        if (res)
          this.setState({ hasFacebookToken: true });
      });

      const twitter = UserProvider.getTwitterToken(this.context.user.businessid).then((res) => {
        if (res)
          this.setState({ hasTwitterToken: true });
      });

      const aggStats = StatisticsProvider.getAggregateStats(this.context.user.businessid).then((results) => {
        if (results) {
          return new Promise<void>((resolve) => {

            let avgi: number = +(results.reduce((a, b) => a + b.interactions, 0) / results.length).toFixed(2);
            let avgv: number = +(results.reduce((a, b) => a + b.views, 0) / results.length).toFixed(2);
            let avga: number = +(results.reduce((a, b) => a + b.attendees, 0) / results.length).toFixed(2);

            this.setState({ averageAttendees: avga, averageInteractions: avgi, averageViews: avgv }, () => {
              resolve();
            })
          });
        }
        return Promise.resolve();
      });

      Promise.all([events, business, facebook, twitter, aggStats]).then(() => {
        // let location = this.state.locations.find(x => x.id === this.state.business.settings?.defaultLocationID) || (this.state.locations.length === 1 ? this.state.locations[0] : null);
        // if (location) {
        //   this.setState({ location, selectedLocationOption: { label: location.name, value: location.id } });
        // }

        if (this.props.history.location.search && this.props.location.search.includes('event')) {
          let eventid = this.props.history.location.search.split('=')[1];
          let event = this.state.events.find(x => x.id === eventid);
          if (event)
            this.handleEventClick(event);
        }

        this.setState({ isLoading: false });
      });
    }
  }

  getMinStartDate = (): Moment => {
    let m = moment();
    return m.minute() || m.second() || m.millisecond() ? m.add(1, 'hour').startOf('hour') : m.startOf('hour');
  }

  getMinEndDate = (startDate: Moment): Moment => {
    let m = moment(startDate).add(1, 'hour');
    return m.minute() || m.second() || m.millisecond() ? m.add(1, 'hour').startOf('hour') : m.startOf('hour');
  }

  getMaxEndDate = (startDate: Moment): Moment => {
    let m = moment(startDate).add(24, 'hours');
    return m.minute() || m.second() || m.millisecond() ? m.add(1, 'hour').startOf('hour') : m.startOf('hour');
  }

  handleEventSave = async () => {
    const matchingEvent = this.state.events.find(x => moment(x.startDate).isSame(this.state.startDate, 'day') && x.id !== this.state.eventid);
    if (matchingEvent) {
      toast.error('Only one event per location per day is allowed.');
      return;
    }

    this.setState({ savingEvent: true });

    //Continue saving the event
    let save: Event = {
      id: this.state.eventid,
      chatid: this.state.chatid,
      thumbnail: this.state.business?.thumbnail,
      name: this.state.name,
      businessID: this.state.business?.id,
      businessName: this.state.business?.name,
      description: this.state.description,
      startDate: this.state.startDate.toDate(),
      endDate: this.state.endDate.toDate(),
      masksRequired: this.state.masksRequired,
      coverCharge: this.state.coverCharge,
      geohash: this.state.business.location.geohash,
      latitude: this.state.business.location.latitude,
      longitude: this.state.business.location.longitude
    };

    EventProvider.saveUpdateEvent(this.context.user.businessid, save, this.state.createChat).then((event) => {
      this.setState({ savingEvent: false });
      if (event.success) {
        let events = this.state.events;
        if (!save.id) {
          events.push(event);
        } else {
          for (let i = 0; i < events.length; i++) {
            if (events[i].id === save.id)
              events[i] = event;
          }
        }
        this.setState({ events: events });
        this.handleEventModalClose();

        // If this was a create and they want prompted and they have social linked - show post queue prompt
        if (!save.id && this.state.business.settings?.promptSocialMediaOnEventSave && (this.state.hasFacebookToken || this.state.hasTwitterToken))
          ConfirmationAlert.confirm(
            'Create Social Media Post(s) For Event?',
            <div>
              Do you want to schedule social media posts for the newly created event?
              <br />
              <br />
              <small>This prompt can be disabled on the settings page.</small>
            </div>
          ).then((res) => {
            if (res)
              this.setState({ queueEventID: event.id, showPostQueueModal: true });
          });

        toast.success('Event saved successfully');
      } else {
        toast.error(event.message);
      }
    });
  }

  handleEventDelete = () => {
    ConfirmationAlert.confirm('Delete Event?', 'Deleting this event will remove it permanently. All of the queued social media posts will be cancelled. This can\'t be undone.', 'danger').then((val) => {
      if (val) {
        this.setState({ savingEvent: true });

        EventProvider.deleteEvent(this.context.user.businessid, this.state.eventid).then((result) => {
          this.setState({ savingEvent: false });
          if (result.success) {
            let events = this.state.events;
            events = events.filter(x => x.id !== this.state.eventid);
            this.setState({ events: events });
            this.handleEventModalClose();
            toast.success('Event removed successfully');
          } else {
            toast.error(result.message);
          }
        });
      }
    });
  }

  handleStartDateChange = (value: Moment) => {
    let endDate = this.state.endDate;
    if (this.state.endDate.isBefore(this.getMinEndDate(value)) || this.state.endDate.isAfter(this.getMaxEndDate(value))) {
      //The current end date is out of range... default it to one hour after new value
      endDate = value.clone().add(1, 'hour');
    }

    this.setState({ startDate: value, endDate: endDate });
  }

  handleEndDateChange = (value) => {
    this.setState({ endDate: value });
  }

  handleEventClick = (event: Event) => {
    window.history.replaceState(null, 'PartyMe', `/Events?event=${event.id}`);
    if (event.endDate > new Date()) {


      // let location = this.state.locations.find(x => x.id == event.locationid);
      this.setState({ showEventModal: true, eventid: event.id, chatid: event.chatid, startDate: moment(event.startDate), endDate: moment(event.endDate), name: event.name, description: event.description, createChat: event.chatid != null });
    } else {
      //Get event stats
      this.setState({ showEventStatModal: true, loadingEventStats: true });
      StatisticsProvider.getEventStat(this.context.user.businessid, event.id).then((eventStat) => {

        let data = [
          {
            stat: 'Interactions',
            average: this.state.averageInteractions,
            event: eventStat?.interactions || 0
          },
          {
            stat: 'Views',
            average: this.state.averageViews,
            event: eventStat?.views || 0
          },
          {
            stat: 'Attendees',
            average: this.state.averageAttendees,
            event: eventStat?.attendees || 0
          }
        ]

        this.setState({ eventStat: { event, data } });
      }).catch((e) => {
        toast.error('Failed to get stats for event.');
      }).finally(() => {
        this.setState({ loadingEventStats: false });
      });
    }
  }

  handleEventModalClose = () => {
    //Clear any event info from the URL if it exists
    window.history.replaceState(null, 'PartyMe', '/Events');
    this.setState({
      showEventModal: false,
      // useCustomLocation: false,
      // customLocation: { status: 'active' } as Location,
      eventid: null,
      chatid: null,
      startDate: this.getMinStartDate(),
      endDate: this.getMinEndDate(this.getMinStartDate()),
      name: '',
      description: '',
      // location: this.state.locations.find(x => x.id === this.state.business.settings?.defaultLocationID) || null,
      // selectedLocationOption: null,
      createChat: this.state.business.settings?.defaultAllowChat
    });
  }

  handleEventStatModalClose = () => {
    window.history.replaceState(null, 'PartyMe', '/Events');
    this.setState({ showEventStatModal: false, eventStat: null })
  }

  handleQueueEventModalClose = () => {
    this.setState({ showPostQueueModal: false, queueEventID: null });
  }

  renderEventStatModalBody = () => {
    // const location = this.state.locations.find(x => x.id === this.state.eventStat?.event?.locationid);

    if (this.state.loadingEventStats)
      return (
        <div className='text-center'>
          <Spinner as='span' animation='border' />
        </div>
      );

    return (
      <>
        <p>{this.state.eventStat?.event?.startDate?.toLocaleString()} - {this.state.eventStat?.event?.endDate?.toLocaleString()}</p>
        <Address
          street1={this.state.business?.location?.street1}
          street2={this.state.business?.location?.street2}
          city={this.state.business?.location?.city}
          state={this.state.business?.location?.state}
          postalCode={this.state.business?.location?.postalCode}
        />
        <hr />
        <ResponsiveContainer height={250}>
          <BarChart data={this.state.eventStat?.data}>
            <XAxis dataKey='stat' />
            <YAxis width={30} />
            <Tooltip />
            <Legend />
            <Bar dataKey='event' fill={COLORS.PRIMARY} />
            <Bar dataKey='average' fill={COLORS.GRAY} />
          </BarChart>
        </ResponsiveContainer>
      </>
    );
  }

  renderEventStatModalButtons = () => {
    return <Button onClick={this.handleEventStatModalClose}>Close</Button>
  }

  renderEventModalBody = () => {
    let disableUpdate = this.state.startDate <= moment() && this.state.eventid != null;
    // let locationOptions = this.state.locations.map(x => { return { label: x.name, value: x.id }; });
    // locationOptions.push({ label: 'New Location', value: '' });
    return (
      <>
        {this.state.eventid && disableUpdate ? <h5 className='text-danger text-center'>Event has started and can't be updated.</h5> : null}
        {this.state.eventid && !disableUpdate && this.state.startDate < moment().add(1, 'day') ? <h5 className='text-danger text-center'>This event is happening soon. Updating the time frame or location is not recommended.</h5> : null}
        <Textbox disabled={disableUpdate} label='Event Name*' onTextChange={(value) => this.setState({ name: value })} value={this.state.name} />
        <Textarea disabled={disableUpdate} label='Description' onTextChange={(value) => this.setState({ description: value })} value={this.state.description} />
        <br />
        <Row>
          <Col lg xs='12'>
            <DateTime
              disabled={disableUpdate}
              maxTime={moment().endOf('day')}
              minTime={this.getMinStartDate().isSame(this.state.startDate, 'day') ? this.getMinStartDate() : moment().startOf('day')}
              maxDate={moment().add(2, 'months')}
              minDate={this.getMinStartDate()}
              onChange={this.handleStartDateChange}
              value={this.state.startDate}
              label='Start Date' />
          </Col>
          <Col lg xs='12'>
            <DateTime
              disabled={disableUpdate}
              maxTime={this.getMaxEndDate(this.state.startDate).isSame(this.state.endDate, 'day') ? this.getMaxEndDate(this.state.startDate) : moment().endOf('day')}
              minTime={this.getMinEndDate(this.state.startDate).isSame(this.state.endDate, 'day') ? this.getMinEndDate(this.state.startDate) : moment().startOf('day')}
              maxDate={this.getMaxEndDate(this.state.startDate)}
              minDate={this.getMinEndDate(this.state.startDate)}
              onChange={this.handleEndDateChange}
              value={this.state.endDate}
              label='End Date' />
          </Col>
        </Row>
        <br />
        {/* {this.state.useCustomLocation ?
          <>
            <Textbox label='Location Name' value={this.state.customLocation.name} onTextChange={(value) => this.handleCustomLocationChange(value, 'name')} />
            <Row>
              <Col>
                <Textbox label='Street 1' value={this.state.customLocation.street1} onTextChange={(value) => this.handleCustomLocationChange(value, 'street1')} />
              </Col>
              <Col>
                <Textbox label='Street 2' value={this.state.customLocation.street2} onTextChange={(value) => this.handleCustomLocationChange(value, 'street2')} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Textbox label='City' value={this.state.customLocation.city} onTextChange={(value) => this.handleCustomLocationChange(value, 'city')} />
              </Col>
              <Col>
                <Textbox label='State' value={this.state.customLocation.state} onTextChange={(value) => this.handleCustomLocationChange(value, 'state')} />
              </Col>
            </Row>
            <Textbox label='Postal Code' value={this.state.customLocation.postalCode} onTextChange={(value) => this.handleCustomLocationChange(value, 'postalCode')} />
          </>

          :

          <Dropdown disabled={disableUpdate} label='Location*' value={this.state.selectedLocationOption} onChange={this.handleLocationChange} options={locationOptions} />
        } */}
        {/* <br /> */}
        <Row>
          <Col xs='6'>
            <CurrencyInput value={this.state.coverCharge} min={0} precision={2} label='Cover Charge' disabled={disableUpdate} onChange={(val) => this.setState({ coverCharge: val })} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Checkbox disabled={disableUpdate} value={this.state.masksRequired} label='Masks required?' onChange={(val) => this.setState({ masksRequired: val })} />
          </Col>
          <Col>
            <Checkbox disabled={disableUpdate} value={this.state.createChat} label='Allow public event chat?' onChange={(val) => this.setState({ createChat: val })} />
          </Col>
        </Row>
      </>
    );
  }

  renderEventModalButtons = () => {
    let disableUpdate = this.state.startDate <= moment();
    return (
      <>
        {this.state.eventid ?
          <Button disabled={this.state.savingEvent || disableUpdate} variant='danger' onClick={() => this.handleEventDelete()} ><FaTrashAlt size={20} /> Delete</Button>
          :
          <Button disabled={this.state.savingEvent} variant='secondary' onClick={() => this.handleEventModalClose()} ><FaTimes size={20} /> Cancel</Button>
        }
        <Button disabled={this.state.savingEvent || disableUpdate} onClick={this.handleEventSave}>{this.state.savingEvent ? <Spinner as='span' animation='border' size='sm' /> : <FaSave size={20} />} Save</Button>
      </>
    );
  }

  getEventProps = (event: Event, start: Date, end: Date, isSelected: boolean) => {
    return {
      className: event.endDate < new Date() ? 'past-date' : ''
    };
  }

  render() {
    return (
      <>
        <GenericModal size='lg' show={this.state.showEventModal} body={this.renderEventModalBody} buttons={this.renderEventModalButtons} title={this.state.eventid ? 'Edit Event' : 'New Event'} onClose={() => this.handleEventModalClose()} />
        <GenericModal show={this.state.showEventStatModal} body={this.renderEventStatModalBody} buttons={this.renderEventStatModalButtons} title={this.state.eventStat?.event?.name} onClose={() => this.handleEventStatModalClose()} />
        {!this.state.isLoading && <PostQueueModal show={this.state.showPostQueueModal} eventid={this.state.queueEventID} allowFacebook={this.state.hasFacebookToken} allowTwitter={this.state.hasTwitterToken} onClose={() => this.handleQueueEventModalClose()} />}

        <Card className='shadow p-3 d-flex' style={{ minHeight: 'calc(100vh - 4rem - 36px)' }}>
          <Row className='my-3'>
            <Col lg='3' md='4' sm='6' xs='12'>
              <Button block onClick={() => this.setState({ showEventModal: true })}><MdAddAlarm size={20} /> Add New Event</Button>
            </Col>
          </Row>

          <Calendar
            events={this.state.events}
            titleAccessor='name'
            allDayAccessor={() => false}
            localizer={localizer}
            startAccessor='startDate'
            endAccessor="endDate"
            showMultiDayTimes
            className='calendar'
            eventPropGetter={this.getEventProps}
            onSelectEvent={this.handleEventClick}
          />
        </Card>
      </>
    );
  }
}