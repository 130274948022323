import React, { Component } from 'react';
import app from 'firebase/app';
import { User, Context, Location } from '../../models';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { Card, Button, Row, Col, ListGroup, Spinner } from 'react-bootstrap';
import { FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import AppContext from '../../AppContext';
import { UserProvider } from '../../providers/UserProvider';
import { toast } from 'react-toastify';
import { MdRefresh } from 'react-icons/md';

interface Props {
  users?: User[],
  loadingUserIDs: string[],
  onDelete: (User) => void
}

interface State {

}

export class UserTable extends Component<Props, State>{
  static contextType = AppContext;

  render() {
    return (
      <ListGroup className='my-3'>
        <ListGroup.Item className='bg-secondary text-white font-weight-bold'>
          <Row>
            <Col sm xs='8'>NAME</Col>
            <Col lg='2' sm='3' xs='4'>ADMIN?</Col>
            <Col lg='4' sm='5' xs='12'>EMAIL</Col>
            <Col lg='3' sm='4' xs='12'>&nbsp;</Col>
          </Row>
        </ListGroup.Item>
        {this.props.users.map(user => {
          let loading = this.props.loadingUserIDs.includes(user.id);
          return (
            <ListGroup.Item key={user.id}>
              <Row>
                <Col sm xs='8'>{user.name}</Col>
                <Col lg='2' sm='3' xs='4'>{user.isAdmin ? 'Yes' : 'No'}</Col>
                <Col lg='4' sm='5' xs='12'>{user.email}</Col>
                <Col lg='3' sm='4' xs='12'>
                  <Row>
                    <Col className='text-right'>
                      {/* <Button disabled={user.isAdmin || loading} title="Reset user's password" size='sm' variant='outline-primary' onClick={() => this.handlePasswordResetClick(user)}><MdRefresh size={15} /></Button> */}
                      <Button disabled={user.isAdmin || loading} title='Delete user' className='ml-2' size='sm' variant='outline-danger' onClick={() => this.props.onDelete(user)}>{loading ? <Spinner as='span' animation='border' size='sm' /> : <FaTrashAlt size={15} />}</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </ListGroup.Item>
          );
        })}
        {this.props.users.length < 1 ? <ListGroup.Item className='text-center'>No Users</ListGroup.Item> : null}
      </ListGroup>
    );
  }
}