import * as firebase from 'firebase';
import { FacebookInsights, FacebookPost, FacebookComment } from '../models';
import moment from 'moment';
import { promises } from 'dns';
import { access } from 'fs';

export class FacebookProvider {
    static getAccountsPages(facebookUserID, shortToken): Promise<any[]> {
        return new Promise((resolve, reject) => {
            //Get long token
            const getLongToken = firebase.functions().httpsCallable('getLongFacebookUserToken');
            getLongToken({ userToken: shortToken }).then((result) => {
                if (result && result.data) {
                    const longToken = result.data;

                    window.FB.api(
                        `/${facebookUserID}/accounts?access_token=${longToken}`,
                        (response) => {
                            if (response && !response.error) {
                                let pages = [];

                                response.data.forEach(page => {
                                    if (page.tasks.includes("ANALYZE") && page.tasks.includes("MODERATE") && page.tasks.includes("CREATE_CONTENT")) {
                                        pages.push(page);
                                    }
                                });

                                resolve(pages);
                            } else {
                                reject(response.error);
                            }
                        }
                    );
                } else {
                    reject(new Error('Failed to get long lived token.'));
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static submitPost(pageID: string, accessToken: string, message: string): Promise<void> {
        return new Promise((resolve, reject) => {
            window.FB.api(
                `${pageID}/feed?access_token=${accessToken}`,
                "POST",
                {
                    "message": message
                },
                (response) => {
                    if (!response || response.error) {
                        reject('Failed to submit post. ' + response.error);
                        return;
                    }

                    resolve();
                }
            );
        });
    }

    static getInsights(pageID: string, accessToken: string): Promise<FacebookInsights> {
        return new Promise((resolve, reject) => {
            let since = moment().add(-8, 'days').startOf('day');
            let until = moment().add(-1, 'day').endOf('day');
            window.FB.api(`${pageID}/insights?since=${since.toDate().toUTCString()}&until=${until.toDate().toUTCString()}&period=day&date_preset=this_week_sun_today&metric=page_fans_online_per_day,page_post_engagements,page_fan_adds_by_paid_non_paid_unique&access_token=${accessToken}`, (response) => {
                if (!response || response.error) {
                    reject('Failed to get insights from Facebook. ' + response.error.message);
                    return;
                }

                let engs = [];
                response.data[0].values.forEach(val => {
                    engs.push({ value: val.value, label: moment(val.end_time).format('M/DD') });
                });

                let adds = [];
                response.data[1].values.forEach(val => {
                    adds.push({ value: val.value.total, label: moment(val.end_time).format('M/DD') });
                });

                resolve({ adds: adds, engagements: engs });
            });
        });
    }

    private static getLikesForPost(postid: string, accessToken: string): Promise<any[]> {
        return new Promise((resolve) => {
            window.FB.api(`${postid}/likes?access_token=${accessToken}`, response => {
                if (!response || response.error) {
                    resolve([]);
                    return;
                }

                resolve(response.data);
            });
        });
    }

    private static getCommentsForPost(postid: string, accessToken: string): Promise<any[]> {
        return new Promise((resolve) => {
            window.FB.api(`${postid}/comments?access_token=${accessToken}`, response => {
                if (!response || response.error) {
                    resolve([]);
                    return;
                }

                resolve(response.data);
            });
        });
    }

    private static getSharesForPost(postid: string, accessToken: string): Promise<any[]> {
        return new Promise((resolve) => {
            window.FB.api(`${postid}/sharedposts?fields=from&access_token=${accessToken}`, response => {
                if (!response || response.error) {
                    resolve([]);
                    return;
                }

                resolve(response.data);
            });
        });
    }

    static likeOrUnlikePost(postid: string, liking: boolean, accessToken: string): Promise<void> {
        return new Promise((resolve, reject) => {
            if (liking) {
                window.FB.api(`${postid}/likes?access_token=${accessToken}`, 'POST', response => {
                    if (!response || response.error) {
                        reject('Failed to like Facebook post. ' + response.error);
                        return;
                    }

                    resolve();
                });
            } else {
                window.FB.api(`${postid}/likes?access_token=${accessToken}`, 'DELETE', response => {
                    if (!response || response.error) {
                        reject('Failed to unlike Facebook post. ' + response.error);
                        return;
                    }

                    resolve();
                });
            }
        });
    }

    static sharePost(pageID: string, accessToken: string, postURL: string): Promise<void> {
        return new Promise((resolve, reject) => {
            window.FB.api(
                `${pageID}/feed?access_token=${accessToken}`,
                "POST",
                {
                    "link": postURL
                },
                (response) => {
                    if (!response || response.error) {
                        reject('Failed to share post. ' + response.error);
                        return;
                    }

                    resolve();
                }
            );
        });
    }

    static getRecentPosts(pageID: string, accessToken: string): Promise<FacebookPost[]> {
        return new Promise((resolve, reject) => {
            window.FB.api(`${pageID}/feed?limit=50&fields=permalink_url,updated_time,message,from,shares,attachments&access_token=${accessToken}`, response => {
                if (!response || response.error) {
                    reject('Failed to get recent posts from Facebook. ' + response.error.message);
                    return;
                }

                let posts: FacebookPost[] = [];

                let proms = [];

                response.data.forEach((resp) => {
                    if (resp.message && resp.message !== '') {
                        let post = {} as FacebookPost;

                        post.from = resp.from.name;
                        post.fromID = resp.from.id;
                        post.id = resp.id;
                        post.message = resp.message;
                        post.shareCount = resp.shares ? resp.shares.count : 0;
                        post.comments = [];
                        post.date = moment(resp.updated_time).format('MM/DD/yyyy');
                        post.postURL = resp.permalink_url;

                        let innerProms = [];

                        //Likes
                        innerProms.push(this.getLikesForPost(post.id, accessToken).then((likes) => {
                            likes.forEach((like) => {
                                if (like.id === pageID) {
                                    post.isLiked = true;
                                }
                            });
                            post.likeCount = likes.length;
                        }));

                        //Comments
                        innerProms.push(this.getCommentsForPost(post.id, accessToken).then((comments) => {
                            let comms: FacebookComment[] = [];
                            comments.forEach((comment) => {
                                comms.push({ id: comment.id, message: comment.message, from: comment.from.name, fromID: comment.from.id });
                            });
                            post.comments = comms;
                            post.commentCount = comments.length;
                        }));

                        //Shares
                        if (post.shareCount) {
                            innerProms.push(this.getSharesForPost(post.id, accessToken).then((shares) => {
                                shares.forEach((share) => {
                                    if (share.from.id === pageID) {
                                        post.isShared = true;
                                    }
                                });
                            }));
                        }

                        proms.push(Promise.all(innerProms).then(() => {
                            posts.push(post);
                        }));
                    }
                });

                Promise.all(proms).then(() => {
                    resolve(posts.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf()));
                });
            });
        });
    }
}