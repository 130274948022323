import { OnApproveData } from '@paypal/paypal-js/types/components/buttons';
import { PayPalButtons } from '@paypal/react-paypal-js';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import AppContext from '../../AppContext';
import { BusinessRegistration, SubscriptionTier } from '../../models';
import PAYPAL_CONFIG from '../../PayPalConfig';
import { AuthProvider } from '../../providers/AuthProvider';

interface Props {
  onCancel: () => void
}

interface State {

}

export default class CancelSubscription extends React.Component<Props, State> {
  static contextType = AppContext;

  handleCancelClick = () => {

  }

  render() {
    return (
      <div style={{ width: 600, maxWidth: '100%' }}>
        <Card className={'shadow m-3 mw-100'}>
          <span className='card-title bg-danger text-white'>Inactive Subscription</span>
          <div style={{ fontSize: 18 }} className='p-3'>
            We have not recieved a payment for your subscription and it has been deactivated. Please sign in to PayPal and submit payment for the subscription, or cancel it and create a new one. You will not be allowed any additional trial months.
            If you need assistance, please email us at <a href='mailto:support@partyme.io'>support@partyme.io</a> .

            <br />

            <Button className='mt-4 mx-auto d-block' variant='danger' onClick={this.props.onCancel}>Cancel Subscription</Button>
          </div>
        </Card>
      </div>
    );
  }
}