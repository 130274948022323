import * as firebase from 'firebase';
import { EventStat, AggregateStat, FollowStat } from '../models';
import { isEqual } from 'date-fns';

export class StatisticsProvider {
    static getEventStat(businessid: string, eventid: string): Promise<EventStat> {
        return firebase.firestore().collection('businesses').doc(businessid).collection('eventstats').doc(eventid).get().then((result) => {
            if (!result.exists)
                return null;

            return { eventid: result.id, ...result.data() } as EventStat;
        });
    }

    static getFollowStats(businessid: string): Promise<FollowStat[]> {
        //Get a date that is the first of the current month, but last year
        let d = new Date();
        let nd = new Date(Date.UTC(d.getFullYear() - 1, d.getMonth()));

        return firebase.firestore().collection('businesses').doc(businessid).collection('followstats').where('date', '>=', nd).orderBy('date').get().then((results) => {
            //Parse each doc
            let tempStats: FollowStat[] = [];
            results.forEach((doc) => {
                let temp = { ...doc.data() };

                temp.date = (temp.date as firebase.firestore.Timestamp).toDate();

                let stat = { ...temp } as FollowStat;

                if (!stat.follows)
                    stat.follows = 0;

                if (!stat.unfollows)
                    stat.unfollows = 0;

                tempStats.push(stat);
            });

            //Go through and make sure there are data points for each month
            //The docs should be in order already
            let stats: FollowStat[] = [];
            let year = d.getFullYear() - 1;
            let month = d.getMonth();
            while (year < d.getFullYear() || month <= d.getMonth()) {
                let stat = tempStats.find(x => isEqual(Date.UTC(year, month), x.date));

                if (stat) {
                    stat.date = new Date(year, month).getTime();
                    stats.push(stat);
                }
                else
                    stats.push({ follows: 0, unfollows: 0, date: new Date(year, month).getTime() });


                month++;
                if (month > 12) {
                    month = 0;
                    year++;
                }
            }

            return stats;
        });
    }

    static getAggregateStats(businessid: string): Promise<AggregateStat[]> {
        //Get a date that is the first of the current month, but last year
        let d = new Date();
        let nd = new Date(Date.UTC(d.getFullYear() - 1, d.getMonth()));

        return firebase.firestore().collection('businesses').doc(businessid).collection('aggregatestats').where('date', '>=', nd).orderBy('date').get().then((results) => {
            //Parse each doc
            let tempStats: AggregateStat[] = [];
            results.forEach((doc) => {
                let temp = { ...doc.data() };

                temp.date = (temp.date as firebase.firestore.Timestamp).toDate();

                let stat = { ...temp } as AggregateStat;

                if (!stat.attendees)
                    stat.attendees = 0;

                if (!stat.interactions)
                    stat.interactions = 0;

                if (!stat.views)
                    stat.views = 0;

                tempStats.push(stat);
            });

            //Go through and make sure there are data points for each month
            //The docs should be in order already
            let stats: AggregateStat[] = [];
            let year = d.getFullYear() - 1;
            let month = d.getMonth();
            while (year < d.getFullYear() || month <= d.getMonth()) {
                let stat = tempStats.find(x => isEqual(Date.UTC(year, month), x.date));

                if (stat) {
                    stat.date = new Date(year, month).getTime();
                    stats.push(stat);
                }
                else
                    stats.push({ attendees: 0, interactions: 0, views: 0, date: new Date(year, month).getTime() });


                month++;
                if (month > 12) {
                    month = 0;
                    year++;
                }
            }

            return stats;
        });
    }
}