import React, { Component } from 'react';
import './Login.css';
import { LogoTop } from '../../shared/LogoTop';
import { Email } from '../../shared/inputs/Email';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Password } from '../../shared/inputs/Password';
import { Button } from 'react-bootstrap';
import { AuthProvider } from '../../providers/AuthProvider';
import { toast } from 'react-toastify';
import AppContext from '../../AppContext';

interface State {
  email: string,
  password: string,
  showResetPassword: boolean
}

export class Login extends Component<RouteComponentProps, State>{
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.state = {
      email: '',
      password: '',
      showResetPassword: false
    };
  }

  handleEmailChange = (value) => {
    this.setState({ email: value });
  }

  handlePasswordChange = (value) => {
    this.setState({ password: value });
  }

  handleLoginClick = () => {
    if (this.state.email !== '' && this.state.password !== '') {
      AuthProvider.loginWithEmailPassword(this.state.email, this.state.password).then((cred) => {
        //
      }).catch((err) => {
        switch (err.code) {
          case 'auth/user-not-found':
            toast.error('Incorrect email.');
            break;
          case 'auth/wrong-password':
            toast.error('Password is incorrect.');
            this.setState({ showResetPassword: true });
            break;
          case 'auth/invalid-email':
            toast.error('Invalid email.');
            break;
          default:
            toast.error('Error logging in. Please try again.');
        }
      });
    } else {
      toast.error('Email and Password are required.');
    }
  }

  handleResetPasswordClick = () => {
    if (this.state.email && this.state.email.includes('@') && this.state.email.includes('.')) {
      AuthProvider.resetPassword(this.state.email).then(() => {
        toast.info('An email was sent to reset your password.');
      }).catch((e) => {
        toast.error('Failed to send password reset email. Make sure your email is correct.');
      });
    } else {
      toast.error('Please enter a valid email in the form above first.');
    }
  }

  render() {
    return (
      <div className='d-flex flex-column align-items-center justify-content-center h-100 w-100'>
        <div style={{ width: 500, maxWidth: '96%' }} className='shadow bg-white'>
          <LogoTop />
          <div className='p-3'>
            <Email onEnterPressed={this.handleLoginClick} label='Email' value={this.state.email} onTextChange={this.handleEmailChange} />
            <Password onEnterPressed={this.handleLoginClick} label='Password' value={this.state.password} onTextChange={this.handlePasswordChange} />
            {/* {this.state.showResetPassword ? <Button variant='warning' block onClick={this.handleResetPasswordClick}>Reset Password</Button> : null} */}
            <Button block onClick={this.handleLoginClick}>Login</Button>
            <div className='text-center py-2'>OR</div>
            <Button onClick={() => this.props.history.push('/register?referer=login')} variant='outline-link' block>Register Business</Button>
          </div>
        </div>
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} className='mt-3' onClick={this.handleResetPasswordClick}>Forgot Password?</span>
      </div>
    );
  }
}