import React, { Component } from 'react';
import './Shared.css';
import logo from '../assets/images/icon.png';
import COLORS from './static/Colors';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { User } from '../models';

interface Props {
  style?: any
}

export class Footer extends Component<Props> {

  render() {
    return (
      <div style={{ backgroundColor: COLORS.PRIMARY, ...this.props.style }}>
        <Container className='py-3'>
          <Row className='text-center'>
            <Col xs='6' >
              <a className='text-white' href='https://attebyte.llc' target='_blank'>{(new Date()).getFullYear()} © Attebyte LLC</a>
            </Col>
            <Col xs='6' >
              <Link className='text-white' to='/support'>Support & FAQ</Link>
            </Col>
          </Row>
          <Row className='text-center'>
            <Col xs='6' >
              <a className='text-white' href='https://attebyte.llc/privacypolicy' target='_blank'>Privacy Policy</a>
            </Col>
            <Col xs='6' >
              <a className='text-white' href='https://attebyte.llc/terms'>Terms & Conditions</a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}