import React, { Component } from 'react';
import { User, Location, Business } from '../../models';
import { Card, Button, Row, Col, Image, Spinner } from 'react-bootstrap';
import { FaPlus, FaUser, FaTimes, FaSave, FaEdit, FaUpload, FaTrashAlt } from 'react-icons/fa';
import AppContext from '../../AppContext';
import { UserProvider } from '../../providers/UserProvider';
import { toast } from 'react-toastify';
import COLORS from '../../shared/static/Colors';
import Dropzone from 'react-dropzone'

import './settings.css';
import { GenericModal } from '../../shared/GenericModal';
import { ConfirmationAlert } from '../../shared/ConfirmationAlert';
import ButtonSpinner from '../../shared/ButtonSpinner';

interface Props {
  business: Business,
  cancelling: boolean,
  onReactivateAccountClick: () => void,
  onCancelSubscriptionClick: () => void
}

interface State {
  imageURL: string,
  showImageModal: boolean,
  uploadingImage: boolean
}

export class ProfileBlock extends Component<Props, State>{
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      imageURL: null,
      showImageModal: false,
      uploadingImage: false
    }
  }

  handleImageModalClose = () => {
    this.setState({ showImageModal: false, imageURL: null, uploadingImage: false });
  }

  handleSaveImageClick = () => {
    this.setState({ uploadingImage: true });
    UserProvider.uploadProfileImage(this.context.user.businessid, this.state.imageURL).then(() => {
      toast.warn('Upload successful. It may take a minute to process.');
    }).catch((err) => {
      console.log(err);
      toast.error('Failed to upload new profile image.');
    }).finally(() => {
      this.handleImageModalClose();
    });
  }

  handleImageDrop = (files: File[]) => {
    if (files && files.length) {
      let file: File = files[0];

      const reader = new FileReader();

      reader.onerror = () => {
        toast.error('Failed to read image file.');
      }

      reader.onload = () => {
        this.setState({ imageURL: reader.result as string })
      }

      reader.readAsDataURL(file);
    }
  }

  renderImageModalContent = () => {
    return (
      <>
        <Dropzone multiple={false} onDrop={this.handleImageDrop} accept={['.jpg', '.jpeg', '.png']}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className='d-flex align-items-center justify-content-center w-100' style={{ minHeight: '200px' }}>
              <input {...getInputProps()} />
              {this.state.imageURL ? <Image src={this.state.imageURL} height={150} width={150} roundedCircle /> : null}
              <p className='text-center'>
                {this.state.imageURL ? null : <span>Drag and drop image here, or click to select. <br /> Square profile images are recommended.</span>}
              </p>
            </div>
          )}
        </Dropzone>
      </>
    );
  }

  renderImageModalButtons = () => {
    return (
      <>
        <Button variant='secondary' onClick={this.handleImageModalClose} disabled={this.state.uploadingImage}><FaTimes size={20} /> Cancel</Button>
        <Button onClick={this.handleSaveImageClick} disabled={this.state.uploadingImage || !this.state.imageURL}>{this.state.uploadingImage ? <Spinner as='span' size='sm' animation='border' /> : <FaUpload size={20} />} Upload</Button>
      </>
    );
  }

  render() {
    return (
      <>
        <GenericModal title='Upload Profile Image' show={this.state.showImageModal} body={this.renderImageModalContent} buttons={this.renderImageModalButtons} onClose={this.handleImageModalClose} />
        <Card className='p-3 shadow h-100'>
          <h3>
            Business Profile
            {this.props.business && this.props.business.subscriptionid && <Button disabled={this.props.cancelling} size='sm' variant='outline-danger' className='float-right' onClick={this.props.onCancelSubscriptionClick}>Close Account</Button>}
            {this.props.business && !this.props.business.subscriptionid && <Button size='sm' variant='outline-primary' className='float-right' onClick={this.props.onReactivateAccountClick}>Reactivate Account</Button>}
          </h3>
          {
            this.props.business && this.props.business.thumbnail && this.props.business.thumbnail !== '' ?

              <div className='profile-image position-relative' style={{ width: '150px', height: '150px' }}>
                <Image height={150} width={150} src={this.props.business.thumbnail} roundedCircle />
                <Button className='shadow' style={{ borderRadius: '50%', position: 'absolute', bottom: 0, right: 0, zIndex: 5, height: 44 }} onClick={() => this.setState({ showImageModal: true })}>
                  <FaEdit size={20} />
                </Button>
              </div>

              :

              <div className='profile-image position-relative bg-secondary d-flex align-items-center justify-content-center' style={{ width: '150px', height: '150px', borderRadius: '50%' }}>
                <FaUser className='w-75 h-75' color='#fff' />
                <Button className='shadow' style={{ borderRadius: '50%', position: 'absolute', bottom: 0, right: 0, zIndex: 5, height: 44 }} onClick={() => this.setState({ showImageModal: true })}>
                  <FaEdit size={20} />
                </Button>
              </div>
          }
          <h5 style={{ width: '150px', textAlign: 'center' }}>{this.props.business ? this.props.business.name : ''}</h5>
        </Card>
      </>
    );
  }
}