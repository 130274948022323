import { SUBSCRIPTION_TIER } from "../../models";
import PAYPAL_CONFIG from "../../PayPalConfig";

export default class Utilities {
    static formatCurrency = (value: number, cultureFormat: string = 'en-US', currencyCode: string = 'USD', minDigits: number, maxDigits: number) => {
        var formatter = new Intl.NumberFormat(cultureFormat, {
            style: 'currency',
            currency: currencyCode,

            // These options are needed to round to whole numbers if that's what you want.
            minimumFractionDigits: minDigits, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            maximumFractionDigits: maxDigits, // (causes 2500.99 to be printed as $2,501)
        });

        return formatter.format(value);
    }

    // static getTierForPayPalPlanID = (planid: string): string => {
    //     let tier: string = null;

    //     Object.keys(PAYPAL_CONFIG.subscriptionPlanIDs).forEach(x => {
    //         if (PAYPAL_CONFIG.subscriptionPlanIDs[x] === planid){
    //             tier = x;
    //         }
    //     });

    //     return tier;
    // }
}