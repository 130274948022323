import React, { Component } from "react";
import { Modal } from 'react-bootstrap';

interface Props {
  show: boolean,
  title: string,
  body: () => JSX.Element | string,
  buttons: () => JSX.Element,
  onClose: () => void,
  size?: 'lg' | 'sm' | 'xl'
}

export class GenericModal extends Component<Props> {
  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} centered size={this.props.size} aria-labelledby='contained-modal-title-vcenter'>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter' style={{ fontWeight: 'bold' }} >{this.props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.props.body()}
        </Modal.Body>

        <Modal.Footer>
          {this.props.buttons()}
        </Modal.Footer>
      </Modal>
    );
  }
}