import React from 'react';
import { Address as AddressModel } from '../models';

interface Props extends AddressModel { }

const Address = (props: Props) => {
  return (
    <>
      <span>{props.street1}</span> <br />
      {props.street2 && <><span>{props.street2}</span><br /></>}
      <span>{props.city}, {props.state}</span> <br />
      <span>{props.postalCode}</span>
    </>
  );
}

export default Address;