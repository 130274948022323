import React, { Component } from 'react';
import { User, Location, Business, Settings } from '../../models';
import { Card, Button, Row, Col, Image, Spinner } from 'react-bootstrap';
import { FaPlus, FaUser, FaTimes, FaSave, FaEdit, FaUpload } from 'react-icons/fa';
import AppContext from '../../AppContext';
import { UserProvider } from '../../providers/UserProvider';
import { toast } from 'react-toastify';
import COLORS from '../../shared/static/Colors';
import Dropzone from 'react-dropzone'

import './settings.css';
import { GenericModal } from '../../shared/GenericModal';
import { ConfirmationAlert } from '../../shared/ConfirmationAlert';
import { LocationProvider } from '../../providers/LocationProvider';
import { Dropdown } from '../../shared/inputs/Dropdown';
import { Checkbox } from '../../shared/inputs/Checkbox';

interface Props {
  business: Business,
  // onLoadingComplete: () => void
}

interface State {
  // locations: Location[],
  // locationid: string,
  // selectedLocationOption: any,
  allowChat: boolean,
  postSocialMedia: boolean,
  saving: boolean
}

export class SettingsBlock extends Component<Props, State>{
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      // locations: [],
      // locationid: this.props.business?.settings?.defaultLocationID || null,
      // selectedLocationOption: null,
      allowChat: this.props.business?.settings?.defaultAllowChat || false,
      postSocialMedia: this.props.business?.settings?.promptSocialMediaOnEventSave || false,
      saving: false
    }
  }

  componentDidMount() {
    // LocationProvider.getLocations(this.context.user.businessid).then((locations) => {
    //   let selectedLocation = null;
    //   if (locations && locations.length) {
    //     selectedLocation = locations.find(x => x.id == this.props.business?.settings?.defaultLocationID);
    //   }
    //   this.setState({ locations, selectedLocationOption: selectedLocation ? { value: selectedLocation.id, label: selectedLocation.name } : null });
    // }).catch((err) => {
    //   console.log(err);
    //   toast.error('Failed to fetch available locations.');
    // }).finally(() => {
    //   this.props.onLoadingComplete();
    // });
  }

  // handleLocationChange = (option) => {
  //   this.setState({ selectedLocationOption: option, locationid: option.value });
  // }

  handleSettingsSaveClick = () => {
    this.setState({ saving: true });

    let settings: Settings = {
      defaultAllowChat: this.state.allowChat,
      // defaultLocationID: this.state.locationid,
      promptSocialMediaOnEventSave: this.state.postSocialMedia
    };
    UserProvider.updateBusinessSettings(this.context.user.businessid, settings).then(() => {
      toast.success('Settings updated.');
    }).catch((err) => {
      console.log(err);
      toast.error('Failed to update settings.');
    }).finally(() => {
      this.setState({ saving: false });
    });
  }

  render() {
    return (
      <Card className='mx-3 p-3 shadow'>
        <h3>Default Settings</h3>
        <Row>
          {/* <Col md='4' sm='6' xs='12'>
            <Dropdown disabled={this.state.locations.length < 1} label='Default Location' value={this.state.selectedLocationOption} onChange={this.handleLocationChange} options={this.state.locations.map(x => { return { label: x.name, value: x.id }; })} />
          </Col> */}
          <Col md='4' sm='6' xs='12'>
            <Checkbox label='Default Allow Party-Wide Chat' value={this.state.allowChat} onChange={(val) => this.setState({ allowChat: val })} />
          </Col>
          <Col md='4' sm='6' xs='12'>
            <Checkbox label='Prompt For Social Media Post on Event Create' value={this.state.postSocialMedia} onChange={(val) => this.setState({ postSocialMedia: val })} />
          </Col>
        </Row>
        <Row className='flex-row-reverse mt-4 mt-sm-0'>
          <Col md='4' xs='12'>
            <Button disabled={this.state.saving} block onClick={this.handleSettingsSaveClick}>{this.state.saving ? <Spinner size='sm' as='span' animation='border' /> : <FaSave size={20} />} Save Settings</Button>
          </Col>
        </Row>
      </Card>
    );
  }
}