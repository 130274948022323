import React, { Component, ReactNode } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  value: boolean,
  label: string | ReactNode,
  disabled?: boolean,
  onChange: (value) => void,
  title?: string
}

export class Checkbox extends Component<Props> {

  render() {
    return (
      <Form.Group title={this.props.title}>
        <Form.Label>{this.props.label}</Form.Label>
        <label className="check-container">
          <input disabled={this.props.disabled} type='checkbox' checked={this.props.value} onChange={() => this.props.onChange(!this.props.value)} />
          <span className="checkmark"></span>
        </label>
      </Form.Group>
    );
  }
}