import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { BusinessRegistration } from '../../models';
import { Email } from '../../shared/inputs/Email';
import { Password } from '../../shared/inputs/Password';
import { Textbox } from '../../shared/inputs/Textbox';

interface Props {
  show: boolean,
  onBack: () => void,
  onSubmitInformation: (info: BusinessRegistration) => void
}

interface State extends BusinessRegistration {

}

export default class BusinessInformation extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      street1: '',
      city: '',
      state: '',
      postalCode: ''
    }
  }

  handleSubmit = () => {
    if (!this.state.name || !this.state.street1.trim() || !this.state.city.trim() || !this.state.state.trim() || !this.state.postalCode.trim()){
      toast.error('Please fill out all fields.');
      return;
    }

    if (this.state.name.trim().length <= 3){
      toast.error('Please enter a valid company name with at least 3 characters.');
    }

    this.props.onSubmitInformation(this.state);
  }

  render() {
    return (
      <div className={!this.props.show ? 'd-none' : ''} style={{ width: 600, maxWidth: '100%' }} >
        <Card className='shadow mx-3 mb-3 mw-100'>
          <span className='card-title'>Business Information</span>
          <div className='p-3'>
            <p className='text-center'>The following information will be used to represent your business. <br /></p>
            <br />
            <Textbox label='Company Name' value={this.state.name} onTextChange={(val) => this.setState({ name: val })} />
            <Row>
              <Col md='6' xs='12'>
                <Textbox label='Street 1' value={this.state.street1} onTextChange={(val) => this.setState({ street1: val })} />
              </Col>
              <Col md='6' xs='12'>
                <Textbox label='Street 2' value={this.state.street2} onTextChange={(val) => this.setState({ street2: val })} />
              </Col>
              <Col md='6' xs='12'>
                <Textbox label='City' value={this.state.city} onTextChange={(val) => this.setState({ city: val })} />
              </Col>
              <Col md='6' xs='12'>
                <Textbox label='State' value={this.state.state} onTextChange={(val) => this.setState({ state: val })} />
              </Col>
              <Col md='6' xs='12'>
                <Textbox label='Postal Code' value={this.state.postalCode} onTextChange={(val) => this.setState({ postalCode: val })} />
              </Col>
            </Row>
          </div>
        </Card>
        <Row className='mx-1'>
          <Col>
            <Button onClick={this.props.onBack} className='shadow' block variant='light'><FaChevronLeft /> Back</Button>
          </Col>
          <Col>
            <Button onClick={this.handleSubmit} className='shadow' block>Continue <FaChevronRight /></Button>
          </Col>
        </Row>
      </div>
    );
  }
}