import * as firebase from 'firebase';
import { Application, FacebookToken, Business, Event, User, Settings, TwitterToken, CloudFunctionReturn } from '../models';
import { AddressProvider } from './AddressProvider';
import { disconnect } from 'process';
import { stringify } from 'querystring';
import { AuthProvider } from './AuthProvider';
import { Observable } from 'rxjs';

export class UserProvider {
    static submitApplication(application: Application): Promise<any> {
        return firebase.firestore().collection('applications').add(application);
    }

    static savePageIDToken(businessID: string, pageID: string, pageToken: string): Promise<any> {
        return firebase.firestore().collection('facebooktokens').doc(businessID).set({ pageID: pageID, pageToken: pageToken });
    }

    static getFacebookToken(businessID: string): Promise<FacebookToken> {
        return firebase.firestore().collection('facebooktokens').doc(businessID).get().then((doc) => {
            if (doc.exists) {
                return { ...doc.data() } as FacebookToken;
            } else {
                return null;
            }
        });
    }

    static getTwitterToken(businessid: string): Promise<TwitterToken> {
        return firebase.firestore().collection('twittertokens').doc(businessid).get().then((doc) => {
            if (doc.exists && doc.data().accessToken) {
                return { ...doc.data() } as TwitterToken;
            } else {
                return null;
            }
        });
    }

    static getBusiness(businessid: string): Promise<Business> {
        return firebase.firestore().collection('businesses').doc(businessid).get().then((doc) => {
            if (doc.exists) {
                return { id: doc.id, ...doc.data() } as Business;
            } else {
                return null;
            }
        });
    }

    static getBusinessSubscription(businessid: string): Observable<Business> {
        return new Observable(subscriber => {
            const unsub = firebase.firestore().collection('businesses').doc(businessid).onSnapshot((snapshot: firebase.firestore.DocumentSnapshot) => {
                subscriber.next({ id: snapshot.id, ...snapshot.data() } as Business);
            });

            return () => {
                unsub();
            }
        });
    }

    static getAllBusinessUsers(businessid: string): Promise<User[]> {
        return firebase.firestore().collection('businessusers').where('businessid', '==', businessid).get().then((docs) => {
            if (docs.empty) {
                return [];
            } else {
                let users: User[] = [];

                docs.forEach(doc => {
                    users.push({ id: doc.id, ...doc.data() } as User);
                });

                return users;
            }
        });
    }

    static createNewUser(businessid: string, name: string, email: string): Promise<User> {
        const createUser = firebase.functions().httpsCallable('createNewBusinessUser');
        return createUser({ businessid, name, email }).then((result) => {
            const data = result.data as User;

            return data;
        });
    }

    static deleteUser(businessid: string, userid: string): Promise<any> {
        const delUser = firebase.functions().httpsCallable('removeBusinessUser');
        return delUser({ businessid, userid });
    }

    static uploadProfileImage(businessid: string, imageURL: string): Promise<any> {
        return firebase.storage().ref(`images/business/${businessid}/image_${new Date().getTime()}`).putString(imageURL, 'data_url').then(() => {
            return true;
        });
    }

    static updateBusinessSettings(businessid: string, settings: Settings) {
        return firebase.firestore().collection('businesses').doc(businessid).update({ settings: settings });
    }

    static submitContactEmail(businessName: string, contactName: string, replyToEmail: string, message: string): Promise<void> {
        const user = AuthProvider.getUser();

        return firebase.firestore().collection('mail').add({
            to: 'team@partyme.io',
            from: 'contactform@partyme.io',
            replyTo: replyToEmail,
            message: {
                subject: 'PartyMe Portal Feedback / Contact',
                html: `
                    SEND DATE:\t ${new Date().toISOString()}
                    BUSINESS ID:\t ${user.uid}\n
                    BUSINESS NAME:\t ${businessName}\n
                    BUSINESS EMAIL:\t ${user.email}
                    CONTACT NAME:\t ${contactName}\n
                    \n
                    ${message}
                `
            }
        }).then(() => {
            return;
        })
    }
}