import * as firebase from 'firebase';
import { FacebookInsights, FacebookPost, FacebookComment, CloudFunctionReturn } from '../models';
import moment from 'moment';
import { promises } from 'dns';
import { access } from 'fs';

export class PayPalProvider {
    static getSubscriptionStatus(businessid: string): Promise<CloudFunctionReturn<{ status: string }>> {
        const status = firebase.functions().httpsCallable('getPayPalSubscriptionStatus');
        return status({ businessid }).then((result) => {
            return result.data as CloudFunctionReturn<{ status: string }>;
        });
    }

    static changeSubscriptionPlan(businessid: string, newPlanID: string): Promise<CloudFunctionReturn<{ redirectURL: string }>> {
        const changePlan = firebase.functions().httpsCallable('changeSubscriptionPlan');
        return changePlan({ businessid, newPlanID }).then((result) => {
            return result.data as CloudFunctionReturn<{ redirectURL: string }>;
        });
    }

    static cancelSubscription(businessid: string): Promise<CloudFunctionReturn<void>> {
        const cancelPlan = firebase.functions().httpsCallable('cancelSubscription');
        return cancelPlan({ businessid }).then((result) => {
            return result.data as CloudFunctionReturn<void>;
        });
    }

    static getPlanPrice(): Promise<CloudFunctionReturn<{ price: number }>> {
        const getPrice = firebase.functions().httpsCallable('getPlanPrice');
        return getPrice().then((result) => {
            return result.data as CloudFunctionReturn<{ price: number }>;
        });
    }
}