import React, { Component } from 'react';
import app from 'firebase/app';
import { User, FacebookToken, TwitterToken, TwitterTweet } from '../../models';
import { RouteComponentProps } from 'react-router-dom';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import AppContext from '../../AppContext';
import { LineChart, XAxis, YAxis, Line, ResponsiveContainer } from 'recharts';
import { LinkTwitter } from './LinkTwitter';

import './Twitter.css';
import { UserProvider } from '../../providers/UserProvider';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Row, Col, Card, Button, Spinner, Badge } from 'react-bootstrap';
import COLORS from '../../shared/static/Colors';
import { FaShare, FaExternalLinkAlt } from 'react-icons/fa';
import { Textarea } from '../../shared/inputs/Textarea';
import { MdSend } from 'react-icons/md';
import { TwitterProvider } from '../../providers/TwitterProvider';
import { BsChatFill, BsHeartFill } from 'react-icons/bs';
import { AiOutlineRetweet } from 'react-icons/ai';

interface Props extends RouteComponentProps {

}

interface State {
  isLoading: boolean,
  userToken: TwitterToken,
  recentMentions: TwitterTweet[],
  recentDMs: any[],
  tweet: string,
  submittingTweet: boolean,
  loadingMentions: boolean,
  favoriting: string,
  retweeting: string,
  replying: TwitterTweet,
  replyMessage: string
}

export class Twitter extends Component<Props, State>{
  static contextType = AppContext;

  constructor(props: Props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      userToken: null,
      recentMentions: [],
      recentDMs: [],
      tweet: '',
      submittingTweet: false,
      loadingMentions: false,
      favoriting: null,
      retweeting: null,
      replying: null,
      replyMessage: ''
    };
  }

  componentDidMount() {
    if (this.context.user)
      this.getTwitterToken();
  }

  getTwitterToken = () => {
    UserProvider.getTwitterToken(this.context.user.businessid).then((token) => {
      this.setState({ userToken: token }, () => {
        if (token) {
          this.getRecentMentions();
        }
      });
    }).catch((err) => {
      console.log(err);
      toast.error('Failed to get twitter access token.');
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  getRecentMentions = () => {
    this.setState({ loadingMentions: true });
    TwitterProvider.getRecentMentions(this.context.user.businessid).then((tweets) => {
      this.setState({ recentMentions: tweets });
    }).catch((err) => {
      toast.error(err.message);
    }).finally(() => {
      this.setState({ loadingMentions: false });
    });
  }

  submitPostClick = () => {
    this.setState({ submittingTweet: true });
    TwitterProvider.submitTweet(this.context.user.businessid, { status: this.state.tweet }).then(() => {
      toast.success('Tweet sent!');
      this.setState({ tweet: '' });
    }).catch((err) => {
      toast.error(err.message);
    }).finally(() => {
      this.setState({ submittingTweet: false });
    });
  }

  favoriteClick = (tweetid: string, favoriting: boolean) => {
    this.setState({ favoriting: tweetid });
    TwitterProvider.favoriteTweet(this.context.user.businessid, tweetid, favoriting).then(() => {
      let tweets = this.state.recentMentions;
      for (let index = 0; index < tweets.length; index++) {
        if (tweets[index].id === tweetid) {
          tweets[index].favorited = favoriting;
          break;
        }
      }
      this.setState({ recentMentions: tweets });
    }).catch((err) => {
      toast.error(err.message);
    }).finally(() => {
      this.setState({ favoriting: null });
    });
  }

  retweetClick = (tweetid: string, retweeting: boolean) => {
    this.setState({ retweeting: tweetid });
    TwitterProvider.retweetTweet(this.context.user.businessid, tweetid, retweeting).then(() => {
      let tweets = this.state.recentMentions;
      for (let index = 0; index < tweets.length; index++) {
        if (tweets[index].id === tweetid) {
          tweets[index].retweeted = retweeting;
          break;
        }
      }
      this.setState({ recentMentions: tweets });
    }).catch((err) => {
      toast.error(err.message);
    }).finally(() => {
      this.setState({ retweeting: null });
    });
  }

  replyClick = (tweetid) => {
    //
  }

  commentReplyClick = (comment) => {
    //
  }

  onLinkComplete = () => {
    //
  }

  render() {
    return (
      <>
        <LoadingIndicator show={this.state.isLoading} />
        {!this.state.userToken && !this.state.isLoading ? <LinkTwitter onLinkComplete={this.onLinkComplete} /> :

          <>
            <Card className='mx-3 mt-3 p-3 shadow'>
              <h3>Create Post</h3>
              <Textarea height={100} placeholder='Write something...' label={null} value={this.state.tweet} onTextChange={(val) => this.setState({ tweet: val })} />
              <Row className='flex-row-reverse'>
                <Col lg='3' md='4' xs='12'>
                  <Button disabled={this.state.submittingTweet} block onClick={this.submitPostClick}>{this.state.submittingTweet ? <Spinner as='span' size='sm' animation='border' /> : <MdSend size={20} />} Submit</Button>
                </Col>
              </Row>
            </Card>
            <Card className='shadow m-3 p-3'>
              <h3>Recent Activity</h3>
              {!this.state.loadingMentions && this.state.recentMentions && this.state.recentMentions.length ?

                this.state.recentMentions.map(x => {
                  return (
                    <Card key={x.id} className='shadow p-3 my-2 position-relative'>
                      <FaExternalLinkAlt onClick={() => { }} color='#d2d2d2' title='Open on Twitter' className='position-absolute' style={{ top: '1rem', right: '1rem', cursor: 'pointer' }} size={15} />
                      <h5>{x.from} <div style={{ color: '#828282', fontSize: '10px' }} className='d-inline-block'>{x.date}</div></h5>
                      <span style={{ color: '#828282', marginTop: '-10px', marginBottom: '15px' }}>@{x.fromUsername}</span>
                      <p>{x.message}</p>
                      <Row>
                        <Col className='text-right'>
                          <Button className='position-relative' onClick={() => this.setState({ replying: this.state.replying && this.state.replying.id === x.id ? null : x, replyMessage: this.state.replying && this.state.replying.id !== x.id ? '' : this.state.replyMessage })}><BsChatFill size={20} /><Badge className='position-absolute' style={{ top: -7, right: -7 }} variant='dark'>{x.commentCount}</Badge></Button>
                          <Button className='position-relative mx-3' onClick={() => this.retweetClick(x.id, !x.retweeted)} title={x.retweeted ? 'Unretweet' : 'Retweet'} variant={x.retweeted ? 'danger' : 'primary'} disabled={this.state.retweeting === x.id}>{this.state.retweeting === x.id ? <Spinner as='span' animation='border' size='sm' /> : <AiOutlineRetweet size={20} />}<Badge className='position-absolute' style={{ top: -7, right: -7 }} variant='dark'>{x.retweetCount}</Badge></Button>
                          <Button className='position-relative' onClick={() => this.favoriteClick(x.id, !x.favorited)} title={x.favorited ? 'Unfavorite' : 'Favorite'} variant={x.favorited ? 'danger' : 'primary'} disabled={this.state.favoriting === x.id}>{this.state.favoriting === x.id ? <Spinner as='span' animation='border' size='sm' /> : <BsHeartFill size={20} />}<Badge className='position-absolute' style={{ top: -7, right: -7 }} variant='dark'>{x.favoriteCount}</Badge></Button>
                        </Col>
                      </Row>
                      {this.state.replying && this.state.replying.id === x.id ? <div className='mt-3'>
                        <Row>
                          <Col className='text-right justify-content-end pr-0'>
                            <Textarea className='m-0' label='' placeholder={`Write reply to @${x.fromUsername}...`} height={'auto'} value={this.state.replyMessage} onTextChange={(val) => this.setState({ replyMessage: val })} />
                          </Col>
                          <Col className='justify-content-end d-flex pl-0' style={{ flexGrow: 0 }}>
                            <Button><MdSend /></Button>
                          </Col>
                        </Row>
                        {x.commentCount ? x.comments.map(com => <Card key={com.id} className='p-3 my-2 bg-light'>
                          <h5>{com.from} <div style={{ color: '#828282', fontSize: '10px' }} className='d-inline-block'>{com.date}</div></h5>
                          <span style={{ color: '#828282', marginTop: '-10px', marginBottom: '15px' }}>@{com.fromUsername}</span>
                          <p>{com.message}</p>
                          <Row>
                            <Col className='text-right'>
                              <Button className='position-relative' onClick={() => this.commentReplyClick(com)}><BsChatFill size={20} /><Badge className='position-absolute' style={{ top: -7, right: -7 }} variant='dark'>{x.commentCount}</Badge></Button>
                              <Button className='position-relative mx-3' onClick={() => this.retweetClick(com.id, !com.retweeted)} title={com.retweeted ? 'Unretweet' : 'Retweet'} variant={com.retweeted ? 'danger' : 'primary'} disabled={this.state.retweeting === com.id}>{this.state.retweeting === com.id ? <Spinner as='span' animation='border' size='sm' /> : <AiOutlineRetweet size={20} />}<Badge className='position-absolute' style={{ top: -7, right: -7 }} variant='dark'>{com.retweetCount}</Badge></Button>
                              <Button className='position-relative' onClick={() => this.favoriteClick(com.id, !com.favorited)} title={com.favorited ? 'Unfavorite' : 'Favorite'} variant={com.favorited ? 'danger' : 'primary'} disabled={this.state.favoriting === com.id}>{this.state.favoriting === com.id ? <Spinner as='span' animation='border' size='sm' /> : <BsHeartFill size={20} />}<Badge className='position-absolute' style={{ top: -7, right: -7 }} variant='dark'>{com.favoriteCount}</Badge></Button>
                            </Col>
                          </Row>
                        </Card>) : null}
                      </div> : null}
                    </Card>
                  );
                })

                :

                null

              }

              {this.state.loadingMentions ? <span className='w-100 text-center'>
                <Spinner as='span' animation='border' />
              </span> : null}

              {!this.state.loadingMentions && !this.state.recentMentions.length ? 'No Recent Mentions' : null}
            </Card>
          </>
        }
      </>
    );
  }
}