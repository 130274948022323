import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import AppContext from "../../AppContext";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { toast } from "react-toastify";

interface Props extends RouteComponentProps { }

interface State { }

export class DeepLink extends Component<Props, State> {
  static contextType = AppContext;

  componentDidMount() {
    const referencetype = this.props.match.params['referencetype'];
    const referenceid = this.props.match.params['referenceid'];

    if (isMobile) {
      window.location.href = `partyme://${referencetype}/${referenceid}`;
      setTimeout(() => {
        if (isAndroid)
          window.location.href = 'https://play.google.com/store/apps/details?id=llc.attebyte.partyme';
        else if (isIOS)
          this.props.history.replace('/'); //TODO update this when we have a version on the app store
      }, 500)
    } else {
      // They aren't on a device that can have the app installed
      // Just send them to the home page - no clue how you even got here
      toast.warn('The selected page is only supported on mobile devices.');
      this.props.history.replace('/');
    }
  }

  render() {
    return (
      <LoadingIndicator show />
    );
  }
}