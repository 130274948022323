import React, { Component } from "react";
import { Row, Col, Button, Card } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import AppContext from "../../AppContext";
import { Textbox } from "../../shared/inputs/Textbox";
import { Email } from "../../shared/inputs/Email";
import { Textarea } from "../../shared/inputs/Textarea";
import { MdSend } from "react-icons/md";
import { toast } from "react-toastify";
import { AuthProvider } from "../../providers/AuthProvider";
import { UserProvider } from "../../providers/UserProvider";
import ButtonSpinner from "../../shared/ButtonSpinner";
import { CheckmarkIcon } from "../../shared/static/Icons";
import COLORS from "../../shared/static/Colors";

interface Props extends RouteComponentProps { }

interface State {
  name: string,
  email: string,
  body: string,
  sending: boolean,
  sent: boolean
}

export class Feedback extends Component<Props, State> {
  static contextType = AppContext;

  constructor(props, context) {
    super(props);

    this.state = {
      body: '',
      email: context.user?.email,
      name: context.user?.name,
      sending: false,
      sent: false
    };
  }

  handleSendClick = () => {
    if (this.state.body === '' || this.state.email === '' || this.state.name === '') {
      toast.error('Please fill out all fields');
      return;
    }

    if (!this.state.email.includes('@') || !this.state.email.includes('.')) {
      toast.error('Email is invalid');
      return;
    }

    this.setState({ sending: true });

    //Actually send it
    UserProvider.submitContactEmail(this.context.user.businessName, this.state.name, this.state.email, this.state.body).then(() => {
      this.setState({ body: '', name: this.context.user?.name, email: this.context.user?.email, sent: true });
    }).catch((e) => {
      toast.error('Failed to submit feedback. Please try again.');
    }).finally(() => {
      this.setState({ sending: false });
    });
  }

  render() {
    return (
      <>
        <Card className='shadow p-3 m-3'>
          <h3>Contact Us</h3>
          <p>Please reach out with any suggestions or feedback for the PartyMe service.</p>
          <br />
          {!this.state.sent &&
            <>
              <Row>
                <Col lg='3' md='4' xs='12'>
                  <Textbox label='Your Name' value={this.state.name} onTextChange={(val) => this.setState({ name: val })} />
                </Col>
                <Col lg='3' md='4' xs='12'>
                  <Email label='Your Email' value={this.state.email} onTextChange={(val) => this.setState({ email: val })} />
                </Col>
              </Row>
              <Row>
                <Col lg='6' md='8' xs='12'>
                  <Textarea height={150} label='Message' value={this.state.body} onTextChange={(val) => this.setState({ body: val })} />
                </Col>
              </Row>
              <Row>
                <Col lg='3' md='4' sm='6' xs='12'>
                  <Button disabled={this.state.sending} block onClick={this.handleSendClick}>{this.state.sending ? <ButtonSpinner show /> : <MdSend size={20} />} Send</Button>
                </Col>
              </Row>
            </>
          }
          {this.state.sent &&
            <div className='w-100 pt-5 text-center'>
              <CheckmarkIcon color={COLORS.PRIMARY} />
              <b style={{ fontSize: 22 }}>Thanks!</b>
            </div>
          }
        </Card>
      </>
    );
  }
}