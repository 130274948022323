import React, { Component } from 'react';
import app from 'firebase/app';
import { User, FacebookToken } from '../../models';
import { RouteComponentProps } from 'react-router-dom';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import AppContext from '../../AppContext';
import { LoginButton } from 'react-facebook';
import { FaFacebook, FaSave } from 'react-icons/fa';
import { Card, Col, Row, ListGroup, Button, Spinner } from 'react-bootstrap';
import { Radio } from '../../shared/inputs/Radio';
import { UserProvider } from '../../providers/UserProvider';
import { FacebookProvider } from '../../providers/FacebookProvider';
import { toast } from 'react-toastify';
import { Dropdown } from '../../shared/inputs/Dropdown';

interface Props {
  onLinkComplete: (token: FacebookToken) => void
}

interface State {
  facebookUserID: string,
  selectedPageToken: string,
  selectedPageID: string,
  selectedPageOption: { label: string, value: string },
  pages: Array<any>,
  saving: boolean,
  loading: boolean
}

export class LinkFacebook extends Component<Props, State>{
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);

    this.state = {
      facebookUserID: null,
      selectedPageToken: null,
      selectedPageID: null,
      selectedPageOption: null,
      pages: [],
      saving: false,
      loading: false
    }
  }

  handleFacebookLoginComplete = (data) => {
    this.setState({ loading: true });
    FacebookProvider.getAccountsPages(data.tokenDetail.userID, data.tokenDetail.accessToken).then((pages) => {
      this.setState({ pages: pages, loading: false });
    }).catch((err) => {
      console.log(err);
      toast.error('Error fetching your information. Please refresh the page to try again.');
    });
  }

  handleFacebookLoginError = (data) => {
    toast.error('There was an issue authenticating with Facebook.');
  }

  handlePageSelect = (option) => {
    const page = this.state.pages.find(x => x.id === option.value);
    this.setState({ selectedPageToken: page.access_token, selectedPageID: page.id, selectedPageOption: option });
  }

  handleSaveClick = () => {
    this.setState({ saving: true });
    UserProvider.savePageIDToken(this.context.user.businessid, this.state.selectedPageID, this.state.selectedPageToken).then(() => {
      this.props.onLinkComplete({ pageID: this.state.selectedPageID, pageToken: this.state.selectedPageToken });
    }).catch((err) => {
      console.log(err);
      toast.error('Error saving information. Please refresh the page and try again.');
    });
  }

  render() {
    if (this.state.pages && this.state.pages.length) {
      return (
        <>
          <LoadingIndicator show={this.state.loading} />
          <Card className='m-3 p-3 shadow'>
            <h5>Choose Page</h5>
            <Row>
              <Col lg='3' md='4' xs='12'>
                <Dropdown onChange={this.handlePageSelect} options={this.state.pages.map((page) => ({ label: page.name, value: page.id }))} value={this.state.selectedPageOption} label={'Select the page you would like to link with PartyMe'} />
              </Col>
            </Row>
            <Row className='flex-row-reverse'>
              <Col lg='3' md='4' sm='6' xs='12'>
                <Button onClick={this.handleSaveClick} disabled={this.state.selectedPageToken === null || this.state.saving} block className='mt-3'>{this.state.saving ? <Spinner as='span' size='sm' animation='border' /> : <FaSave size={20} />} Save</Button>
              </Col>
            </Row>
          </Card>
        </>
      );
    }

    return (
      <Card className='m-3 p-3 shadow'>
        <h5>Link Facebook Page</h5>
        <span>
          In order to access and manage your business page, we need to authenticate with Facebook on your behalf.
          Please make sure you login in to the account that has access to the page, then click the button below.
          <br />
          <span className='text-danger'>Note: You need to have administrative privileges to your business' Facebook page.</span>
        </span>
        <LoginButton className='facebook-btn mt-3' scope='email,read_insights,pages_show_list,pages_manage_metadata,pages_manage_posts' onCompleted={this.handleFacebookLoginComplete} onError={this.handleFacebookLoginError}>
          <span><FaFacebook style={{ marginTop: '-5px', fontWeight: 'bold' }} size={20} /> Sign In With Facebook</span>
        </LoginButton>
      </Card>
    );
  }
}