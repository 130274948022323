import React, { Component } from 'react';
import app from 'firebase/app';
import { User, Location, Business } from '../../models';
import { RouteComponentProps } from 'react-router-dom';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { Card, Button, Row, Col, Image, Spinner } from 'react-bootstrap';
import { FaPlus, FaUser, FaTimes, FaSave } from 'react-icons/fa';
// import { LocationTable } from './LocationTable';
import AppContext from '../../AppContext';
import { Textbox } from '../../shared/inputs/Textbox';
import { Number } from '../../shared/inputs/Number';
import { GenericModal } from '../../shared/GenericModal';
import { UserProvider } from '../../providers/UserProvider';
import { toast } from 'react-toastify';
import { ConfirmationAlert } from '../../shared/ConfirmationAlert';
import { MdLocationSearching } from 'react-icons/md';
import { LocationProvider } from '../../providers/LocationProvider';
import { UserTable } from './UserTable';
import { Email } from '../../shared/inputs/Email';

interface Props {
  onLoadingComplete: () => void
}

interface State {
  users: User[],
  showUserModal: boolean,
  userName: string,
  userEmail: string,
  savingUser: boolean,
  loadingUserIDs: string[]
}

export class UsersBlock extends Component<Props, State>{
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      users: [],
      showUserModal: false,
      userName: '',
      userEmail: '',
      savingUser: false,
      loadingUserIDs: []
    }
  }

  componentDidMount() {
    UserProvider.getAllBusinessUsers(this.context.user.businessid).then((users) => {
      this.setState({ users });
    }).catch((err) => {
      console.log(err);
      toast.error('Failed to get a list of authenticated users');
    }).finally(() => {
      this.props.onLoadingComplete();
    });
  }

  handleUserDelete = (user: User) => {
    ConfirmationAlert.confirm('Delete User?', <span>Are you sure you want to remove <strong>{user.name}</strong>?</span>, 'danger').then((res) => {
      if (res) {
        let loading = this.state.loadingUserIDs;
        loading.push(user.id);
        this.setState({ loadingUserIDs: loading });
        UserProvider.deleteUser(this.context.user.businessid, user.id).then(() => {
          let users = this.state.users;
          users = users.filter(x => x.id !== user.id);
          this.setState({ users });
          toast.success('User was removed successfully')
        }).catch((err) => {
          console.log(err);
          toast.error('Failed to remove user');
        }).finally(() => {
          loading = this.state.loadingUserIDs;
          loading = loading.filter(x => x !== user.id);
          this.setState({ loadingUserIDs: loading });
        });
      }
    });
  }

  handleNewUserSave = () => {
    if (this.state.userName && this.state.userEmail && this.state.userName !== '' && this.state.userEmail !== '') {
      this.setState({ savingUser: true });
      UserProvider.createNewUser(this.context.user.businessid, this.state.userName, this.state.userEmail).then((user) => {
        if (user) {
          let users = this.state.users;
          users.push(user);
          this.setState({ users: users });
          this.handleNewUserModalClose();
          ConfirmationAlert.alert('New User Created', <p>The email {user.email} can now be used to access the PartyMe Portal for your business. Their password is <strong>{user.password}</strong></p>);
        } else {
          this.setState({ savingUser: false });
        }
      }).catch((err) => {
        console.log(err);
        this.setState({ savingUser: false });
        toast.error('Error creating user. Make sure the email is valid.');
      });
    } else {
      toast.error('All fields are required.');
    }
  }

  handleNewUserModalClose = () => {
    this.setState({ userEmail: '', userName: '', showUserModal: false, savingUser: false });
  }


  renderUserModalContent = () => {
    return (
      <>
        <Email value={this.state.userEmail} onTextChange={(value) => this.setState({ userEmail: value })} label='Email' description="Your email, or the person's who will be using the account. It will be used to login and reset the account's password." />
        <Textbox value={this.state.userName} onTextChange={(value) => this.setState({ userName: value })} label='Name' />
      </>
    );
  }

  renderUserModalButtons = () => {
    return (
      <>
        <Button disabled={this.state.savingUser} variant='secondary' onClick={this.handleNewUserModalClose}><FaTimes size={20} /> Cancel</Button>
        <Button disabled={this.state.savingUser} onClick={this.handleNewUserSave} >{this.state.savingUser ? <Spinner as='span' size='sm' animation='border' /> : <FaSave size={20} />} Save</Button>
      </>
    );
  }

  render() {
    return (
      <>
        <GenericModal title={'New User'} show={this.state.showUserModal} body={this.renderUserModalContent} buttons={this.renderUserModalButtons} onClose={this.handleNewUserModalClose} />

        <Card className='p-3 shadow h-100 position-relative'>
          <h3>Authorized Users</h3>
          <Button title='New User' onClick={() => this.setState({ showUserModal: true })} className='position-absolute' style={{ top: '1rem', right: '1rem' }}><FaPlus size={20} /></Button>
          <UserTable loadingUserIDs={this.state.loadingUserIDs} users={this.state.users} onDelete={this.handleUserDelete} />
        </Card>
      </>
    );
  }
}