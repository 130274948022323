import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { Row, Col } from 'react-bootstrap';
import AppContext from '../../AppContext';
// import { LocationsBlock } from './LocationsBlock';
import { UsersBlock } from './UsersBlock';
import { ProfileBlock } from './ProfileBlock';
import { SettingsBlock } from './SettingsBlock';
import { UserProvider } from '../../providers/UserProvider';
import { Business } from '../../models';
import { ConfirmationAlert } from '../../shared/ConfirmationAlert';
import { PayPalProvider } from '../../providers/PayPalProvider';
import { toast } from 'react-toastify';

interface Props extends RouteComponentProps {

}

interface State {
  business: Business,
  isLoading: boolean,
  cancellingSubscription: boolean,
  usersResolve?: () => void,
  locationsResolve?: () => void,
  // settingsResolve?: () => void,
  businessResolve?: () => void
}

export class Settings extends Component<Props, State>{
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);

    let l, u, s, b;

    Promise.all([
      // new Promise((resolve) => l = resolve), 
      new Promise((resolve) => u = resolve),
      // new Promise((resolve) => s = resolve),
      new Promise((resolve) => b = resolve)
    ]).then(() => {
      this.setState({ isLoading: false });
    })

    this.state = {
      isLoading: true,
      business: null,
      cancellingSubscription: false,
      // locationsResolve: l,
      usersResolve: u,
      // settingsResolve: s,
      businessResolve: b
    };
  }

  componentDidMount() {
    UserProvider.getBusiness(this.context.user.businessid).then((business) => {
      this.setState({ business });
      this.state.businessResolve();
    });
  }

  handleCancelAccountClick = () => {
    ConfirmationAlert.confirm(
      'Cancel Subscription',
      `Are you sure you want to cancel your subscription and lose access to the PartyMe Portal? You will maintain access until the current billing period expires.`,
      'danger'
    ).then((result) => {
      if (result) {
        this.setState({ cancellingSubscription: true });
        PayPalProvider.cancelSubscription(this.state.business.id).then((result) => {
          if (result.success) {
            toast.error('Something went wrong while cancelling the subscription. Please try again. If the issue persists, you can cancel directly through your PayPal account.', { autoClose: false });
          }
        }).finally(() => {
          this.setState({ cancellingSubscription: false });
        });
      }
    });
  }

  handleReactivateAccountClick = () => {
    this.props.history.push('/register');
  }

  render() {
    if (!this.state.business)
      return <LoadingIndicator show />;

    return (
      <>
        <LoadingIndicator show={this.state.isLoading} />
        <Row className='py-3 mx-0 w-100'>
          <Col lg='6' xs='12' className='mb-lg-0 mb-3'>
            <ProfileBlock cancelling={this.state.cancellingSubscription} onReactivateAccountClick={this.handleReactivateAccountClick} onCancelSubscriptionClick={this.handleCancelAccountClick} business={this.state.business} />
          </Col>
          <Col lg='6' xs='12'>
            <UsersBlock onLoadingComplete={this.state.usersResolve} />
          </Col>
        </Row>
        <SettingsBlock business={this.state.business} />
        {/* <LocationsBlock business={this.state.business} onLoadingComplete={this.state.locationsResolve} /> */}
      </>
    );
  }
}