import React from 'react';
import COLORS from './Colors';

interface Props extends React.SVGProps<SVGAElement> {
  width?: number,
  height?: number,
  color?: string
}

export class PartyHatIcon extends React.Component<Props> {
  render() {
    return (
      <svg className={this.props.className} xmlns="http://www.w3.org/2000/svg"
        width={this.props.width} height={this.props.height}
        viewBox="0 0 148 241"
        fill={this.props.color || COLORS.WHITE} >
        <path id="Unnamed" d="M 66.12,46.00
          C 66.75, 25.62 74.62, 14.50 102.25, 0.75
          102.25, 0.75 117.00, 15.25 117.00, 15.25
          85.88, 21.88 79.50, 37.50 77.75, 44.88
          103.00, 26.38 115.00, 32.12 131.38, 35.50
          131.38, 35.50 131.25, 53.38 131.25, 53.38
          110.25, 42.38 89.88, 45.50 83.25, 51.25
          100.38, 52.50 109.88, 55.25 122.88, 73.75
          122.88, 73.75 110.12, 84.88 110.12, 84.88
          109.62, 81.38 100.62, 60.38 82.12, 58.38
          82.12, 58.38 147.44, 213.47 147.44, 213.47
          125.75, 242.62 35.75, 256.00 0.44, 215.50
          0.44, 215.50 66.12, 46.00 66.12, 46.00 Z" />
      </svg>
    );
  }
}

export class CheckmarkIcon extends React.Component<Props> {
  render() {
    return (
      <svg className='animated-icon' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
        <circle className="path circle" fill="none" stroke={this.props.color} strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
        <polyline className="path check" fill="none" stroke={this.props.color} strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
      </svg>
    );
  }
}

export class TimesIcon extends React.Component<Props> {
  render() {
    return (
      <svg className='animated-icon' version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
        <circle className="path circle" fill="none" stroke={this.props.color} strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
        <line className="path line" fill="none" stroke={this.props.color} strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
        <line className="path line" fill="none" stroke={this.props.color} strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
      </svg>
    );
  }
}