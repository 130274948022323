import { debugMode } from './App';

const PAYPAL_CONFIG: { clientID: string, subscriptionPlanID: string } = window.location.hostname === 'localhost' || debugMode ? {
  clientID: "Ac6ZgKkhwR50ZR6s0JEMfdwEKsu_VWrPCsH-bW-OMbzvlMxFPVrKX5BLNNs_P-JzlQmfPBxN3KeVy0A3",
  subscriptionPlanID: 'P-7TA22207U8162450NMH4EPGI'
} : {
  clientID: 'AUGAnQmznvjf2ZpIucGagGsz2-6JX4-FiFiPPFCJ-DOwTGLzAHvYZWcP55TKylFOE0ZXvVwhTbpUqiM_',
  subscriptionPlanID: 'P-4YH05197MP168810AMHW5EXQ'
}

export default PAYPAL_CONFIG;