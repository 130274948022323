import { OnApproveData } from '@paypal/paypal-js/types/components/buttons';
import { PayPalButtons } from '@paypal/react-paypal-js';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import AppContext from '../../AppContext';
import { BusinessRegistration, SubscriptionTier } from '../../models';
import PAYPAL_CONFIG from '../../PayPalConfig';
import { AuthProvider } from '../../providers/AuthProvider';

interface Props {
  show: boolean,
  data: OnApproveData,
  isLoggedIn: boolean
}

interface State extends BusinessRegistration {

}

export default class Error extends React.Component<Props, State> {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);

    // this.state = {

    // }
  }

  render() {
    if (!this.props.data)
      return null;

    return (
      <div className={!this.props.show ? 'd-none' : ''} style={{ width: 600, maxWidth: '100%' }}>
        <Card className={'shadow m-3 mw-100'}>
          <span className='card-title text-danger'>Error</span>
          <div style={{ fontSize: 18 }} className='p-3'>
            <strong style={{ fontSize: 22 }}>Order Number: {this.props.data.orderID}</strong>
            <hr/>
            <strong style={{ fontSize: 22 }}>Order Details</strong> <br/>
            $100 /mo <br/>
            1 Months Free Trial
            <hr/>
            <strong style={{ fontSize: 22 }}>Additional Information</strong> <br/>
            <p className='text-center'>Please do not use your account. Your payment and order information has been saved. Please contact us at <a href='mailto:support@partyme.io'>support@partyme.io</a> and we will get your account up and running!</p>
            {this.props.isLoggedIn && <Button onClick={() => AuthProvider.signOut()} block className='mt-2'>Logout</Button>}
          </div>
        </Card>
      </div>
    );
  }
}