import React, { Component } from "react";
import { LogoTop } from "../../shared/LogoTop";
import { Row, Col, Jumbotron, Container, Button, Card, Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import AppContext from "../../AppContext";
import { Footer } from "../../shared/Footer";
import './Register.css';
import { AccountRegistration, Business, BusinessRegistration, Geo, SubscriptionTier, SUBSCRIPTION_TIERS } from "../../models";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Payment from "./Payment";
import Complete from "./Complete";
import { OnApproveData } from "@paypal/paypal-js/types/components/buttons";
import { RegistrationProvider } from "../../providers/RegistrationProvider";
import { toast } from "react-toastify";
import Error from "./Error";
import AccountInformation from "./AccountInformation";
import BusinessInformation from "./BusinessInformation";
import { AddressProvider } from "../../providers/AddressProvider";
import { UserProvider } from "../../providers/UserProvider";
import Overview from "./Overview";
import { PayPalProvider } from "../../providers/PayPalProvider";

interface Props extends RouteComponentProps { }

enum CURRENT_STEP {
  'ACCOUNT_INFORMATION',
  'BUSINESS_INFORMATION',
  'PAYMENT',
  'COMPLETE',
  'ERROR'
}

interface State {
  newBusinessID: string,
  currentStep: CURRENT_STEP,
  showOverview: boolean,
  locationGeocode: Geo,
  paymentData: OnApproveData,
  businessInfo: BusinessRegistration,
  accountInfo: AccountRegistration,
  business: Business,
  planPrice: number,
  hasValidReferral: boolean,
  loading: boolean
}

export class Register extends Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props, context) {
    super(props);

    this.state = {
      newBusinessID: RegistrationProvider.getNewBusinessIDForRegistration(),
      currentStep: context?.user?.businessid ? CURRENT_STEP.PAYMENT : CURRENT_STEP.ACCOUNT_INFORMATION,
      showOverview: props.location.search.includes('referer') && !context?.user?.businessid,
      planPrice: 100,
      hasValidReferral: false,
      locationGeocode: null,
      paymentData: null,
      businessInfo: null,
      accountInfo: null,
      business: null,
      loading: true
    }
  }

  componentDidMount(): void {
    if (this.context?.user?.businessid) {
      UserProvider.getBusiness(this.context.user.businessid).then((business) => {
        this.setState({ business });
      });
    }

    // Get the current price of the plan
    PayPalProvider.getPlanPrice().then((result) => {
      if (result.success) {
        this.setState({ planPrice: result.price, loading: false });
      } else {
        console.log(result);
        toast.error('There was an error. Please refresh the page. If the issue persists, please contact support@partyme.io .', { autoClose: false });
      }
    });
  }

  // handleTierSelected = (tier: SubscriptionTier) => {
  //   this.setState({ currentStep: this.context.user?.businessid ? CURRENT_STEP.PAYMENT : CURRENT_STEP.INFORMATION, selectedTier: tier });
  // }

  handleAccountInfoSubmit = (info: AccountRegistration) => {
    this.setState({ loading: true });

    RegistrationProvider.canRegisterBusiness(info.email).then((res) => {
      if (!res.success)
        toast.error(res.message, { autoClose: false });
      else {
        if (info.referralCode) {
          return RegistrationProvider.validateReferralCode(info.referralCode).then((result) => {
            if (result.isValid) {
              toast.success('12 month trial referral code has been activated.');
              this.setState({ currentStep: CURRENT_STEP.BUSINESS_INFORMATION, accountInfo: info, hasValidReferral: true });
            } else {
              toast.error('Failed to validate referral code. Please double check what was entered, or remove it altogether to continue.');
            }
          });
        } else {
          this.setState({ currentStep: CURRENT_STEP.BUSINESS_INFORMATION, accountInfo: info });
        }
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleBusinessInfoSubmit = (info: BusinessRegistration) => {
    this.setState({ loading: true });

    AddressProvider.geocode(info.street1, info.city, info.state, info.postalCode).then((result) => {
      if (result) {
        this.setState({ currentStep: CURRENT_STEP.PAYMENT, businessInfo: info, locationGeocode: result });
      } else {
        toast.error('Failed to validate and geocode the address entered. A valid address is required to continue.');
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleBusinessInfoBack = () => {
    this.setState({ currentStep: CURRENT_STEP.ACCOUNT_INFORMATION });
  }

  // handleCompanyInfoBack = () => {
  //   this.setState({ currentStep: CURRENT_STEP.TIER });
  // }

  handlePaymentBack = () => {
    this.setState({ currentStep: CURRENT_STEP.BUSINESS_INFORMATION });
  }

  handlePaymentSuccess = (data: OnApproveData) => {
    this.setState({ loading: true });

    if (this.context?.user?.businessid) {
      RegistrationProvider.reactivateBusinessAccount(this.context.user.businessid, data.subscriptionID, data.orderID).then((res) => {
        if (!res.success) {
          toast.error(res.message, { autoClose: false });
          this.setState({ currentStep: CURRENT_STEP.ERROR, paymentData: data });
        } else {
          this.setState({ currentStep: CURRENT_STEP.COMPLETE, paymentData: data });
        }
      }).finally(() => {
        this.setState({ loading: false });
      })
    } else {
      RegistrationProvider.createNewBusinessAccount({
        companyName: this.state.businessInfo.name,
        email: this.state.accountInfo.email,
        firstName: this.state.accountInfo.firstName,
        lastName: this.state.accountInfo.lastName,
        newBusinessID: this.state.newBusinessID,
        orderID: data.orderID,
        password: this.state.accountInfo.password,
        paymentID: data.paymentID,
        subscriptionID: data.subscriptionID,
        street1: this.state.businessInfo.street1,
        street2: this.state.businessInfo.street2,
        city: this.state.businessInfo.city,
        state: this.state.businessInfo.state,
        postalCode: this.state.businessInfo.postalCode,
        latitude: this.state.locationGeocode.latitude,
        longitude: this.state.locationGeocode.longitude,
        geohash: this.state.locationGeocode.geohash,
        referralCode: this.state.accountInfo.referralCode
      }).then((res) => {
        if (!res.success) {
          toast.error(res.message, { autoClose: false });
          this.setState({ currentStep: CURRENT_STEP.ERROR, paymentData: data });
        } else {
          this.setState({ currentStep: CURRENT_STEP.COMPLETE, paymentData: data });
        }
      }).finally(() => {
        this.setState({ loading: false });
      })
    }
  }

  render() {
    return (
      <div className='h-100 w-100 d-flex flex-column'>
        <LogoTop user={this.context.user} history={this.props.history} showLogout />
        <Stepper className='register-steps' activeStep={this.state.currentStep}>
          {/* <Step key={CURRENT_STEP.TIER}>
            <StepLabel>{this.state.currentStep === CURRENT_STEP.TIER ? 'Select Tier' : `${this.state.selectedTier.name} ($${this.state.selectedTier.price})`}</StepLabel>
          </Step> */}
          <Step key={CURRENT_STEP.ACCOUNT_INFORMATION}>
            <StepLabel>Account</StepLabel>
          </Step>
          <Step key={CURRENT_STEP.BUSINESS_INFORMATION}>
            <StepLabel>Business</StepLabel>
          </Step>
          <Step key={CURRENT_STEP.PAYMENT}>
            <StepLabel>Payment</StepLabel>
          </Step>
          <Step key={CURRENT_STEP.COMPLETE}>
            <StepLabel>Confirmation</StepLabel>
          </Step>
        </Stepper>
        {this.context?.user?.businessid && this.state.business &&
          <div className='p-3 align-self-center' style={{ width: 800, maxWidth: '100%' }}>
            <div className='w-100 p-1 text-danger border-danger text-center font-weight-bold' style={{ border: '3px solid', fontSize: 20 }}>
              The subscription for {this.state.business.name} has been cancelled.
              {
                this.state.business.activeuntil.toDate() > new Date() ?
                  ` You're access will be revoked on ${this.state.business.activeuntil.toDate().toLocaleDateString()}. `
                  :
                  ' You\'re access has been revoked. '
              }
              To reactivate the account, please complete payment through PayPal below.
            </div>
          </div>
        }
        <div className='mb-3 d-flex flex-grow-1 align-items-center justify-content-center'>
          {this.state.loading &&
            <div className='text-center'>
              <Spinner as='span' animation='border' />
              <br />
              {this.state.currentStep === CURRENT_STEP.PAYMENT && <b>Processing payment. <br /> Please do not leave this page.</b>}
            </div>
          }
          {/* <TierList show={this.state.currentStep === CURRENT_STEP.TIER && !this.state.loading} onTierSelect={this.handleTierSelected} /> */}
          <Overview show={this.state.showOverview && !this.state.loading} price={this.state.planPrice} onContinue={() => this.setState({ showOverview: false })} />
          {!this.state.showOverview && <>
            <AccountInformation show={this.state.currentStep === CURRENT_STEP.ACCOUNT_INFORMATION && !this.state.loading} onSubmitInformation={this.handleAccountInfoSubmit} />
            <BusinessInformation show={this.state.currentStep === CURRENT_STEP.BUSINESS_INFORMATION && !this.state.loading} onBack={this.handleBusinessInfoBack} onSubmitInformation={this.handleBusinessInfoSubmit} />
            <Payment hasReferral={this.state.hasValidReferral} planPrice={this.state.planPrice}  onComplete={this.handlePaymentSuccess} onBack={this.handlePaymentBack} newBusinessID={this.state.newBusinessID} show={this.state.currentStep === CURRENT_STEP.PAYMENT && !this.state.loading} />
            <Complete onLoginClick={() => this.props.history.push('/login')} price={this.state.planPrice} onDashboardClick={() => this.props.history.push('/dashboard')} show={this.state.currentStep === CURRENT_STEP.COMPLETE && !this.state.loading} accountInfo={this.state.accountInfo} businessInfo={this.state.businessInfo} data={this.state.paymentData} />
            <Error isLoggedIn={this.context.user?.businessid !== null && this.context.user?.businessid !== undefined} show={this.state.currentStep === CURRENT_STEP.ERROR && !this.state.loading} data={this.state.paymentData} />
          </>}
        </div>
        <Footer />
      </div>
    );
  }
}