import { Geo, Location } from "../models";
import * as firebase from 'firebase';

export class AddressProvider {
    static setLocationGeoInformation(location: Location): Promise<Location> {
        return this.geocode(location.street1, location.city, location.state, location.postalCode).then((result) => {
            if (result) {
                location.success = true;
                location.latitude = result.latitude;
                location.longitude = result.longitude;
                location.geohash = result.geohash;
                return location;
            } else {
                location.success = false;
                location.message = 'Failed to validate address. A valid address is required.';
                return location;
            }
        });
    }

    static geocode(street1: string, city: string, state: string, postalCode: string): Promise<Geo> {
        const geocode = firebase.functions().httpsCallable('geocode');
        return geocode({ street1, city, state, postalCode }).then((result) => {
            const data = result.data as Geo;

            return data;
        }).catch(() => {
            return null;
        });
    }
}