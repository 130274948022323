import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import moment, { Moment } from 'moment'

import 'react-datepicker/dist/react-datepicker.min.css';

moment.locale('en');

interface Props {
  value: Moment,
  label: string,
  placeholder?: string,
  minDate?: Moment,
  maxDate?: Moment,
  minTime?: Moment,
  maxTime?: Moment,
  disabled?: boolean,
  onChange: (value: Moment) => void
}

export class DateTime extends Component<Props> {

  render() {
    return (
      <Form.Group>
        <Form.Label>{this.props.label}</Form.Label><br/>
        <DatePicker 
          disabled={this.props.disabled}
          className='datetime-picker' 
          showTimeSelect 
          dateFormat="MMMM d, yyyy h:mm aa" 
          selected={this.props.value.toDate()} 
          maxTime={this.props.maxTime ? this.props.maxTime.toDate() : null}
          minTime={this.props.minTime ? this.props.minTime.toDate() : null} 
          maxDate={this.props.maxDate ? this.props.maxDate.toDate() : null} 
          minDate={this.props.minDate ? this.props.minDate.toDate() : null} 
          onChange={(value) => this.props.onChange(moment(value))} />
      </Form.Group>
    );
  }
}