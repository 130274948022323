import * as firebase from 'firebase';
import { TwitterTweet } from '../models';
import moment from 'moment';

export class TwitterProvider {
    static getAuthURL(businessid: string): Promise<string> {
        return new Promise((resolve, reject) => {
            //Get long token
            const getAuthURL = firebase.functions().httpsCallable('getTwitterAuthURL');
            getAuthURL({ businessid }).then((result) => {
                if (result && result.data && result.data.success) {
                    resolve(result.data.url);
                } else {
                    reject(new Error(result.data.message || 'Failed to get twitter authentication URL.'));
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static getRecentMentions(businessid: string): Promise<TwitterTweet[]> {
        return new Promise((resolve, reject) => {
            //Get long token
            const getMentions = firebase.functions().httpsCallable('getTwitterRecentMentions');
            getMentions({ businessid }).then((result) => {
                if (result && result.data && result.data.success) {
                    if (Array.isArray(result.data.data)) {
                        let tweets: TwitterTweet[] = [];

                        result.data.data.forEach((t) => {
                            if (!t.in_reply_to_status_id_str) {
                                let tweet: TwitterTweet = {} as TwitterTweet;

                                tweet.date = moment(t.created_at).format('MM/DD/yyyy');
                                tweet.favoriteCount = t.favorite_count;
                                tweet.favorited = t.favorited;
                                tweet.from = t.user.name;
                                tweet.fromUsername = t.user.screen_name;
                                tweet.id = t.id_str;
                                tweet.message = t.text;
                                tweet.retweetCount = t.retweet_count;
                                tweet.retweeted = t.retweeted;

                                //Find the comments
                                let comms: TwitterTweet[] = result.data.data.filter(x => x.in_reply_to_status_id_str == tweet.id).map(x => {
                                    return {
                                        date: moment(x.created_at).format('MM/DD/yyyy'),
                                        favoriteCount: x.favorite_count,
                                        favorited: x.favorited,
                                        from: x.user.name,
                                        fromUsername: x.user.screen_name,
                                        id: x.id_str,
                                        message: x.text,
                                        retweetCount: x.retweet_count,
                                        retweeted: x.retweeted
                                    } as TwitterTweet;
                                }).reverse();

                                tweet.comments = comms;
                                tweet.commentCount = comms.length;

                                tweets.push(tweet);
                            }
                        });

                        resolve(tweets.sort((a, b) => moment(a.date).milliseconds() - moment(b.date).milliseconds()));
                    } else {
                        resolve([]);
                    }
                } else {
                    reject(new Error(result.data.message || 'Failed to get twitter mentions.'));
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static submitTweet(businessid: string, tweet: any): Promise<void> {
        return new Promise((resolve, reject) => {
            //Get long token
            const sendTweet = firebase.functions().httpsCallable('submitTweet');
            sendTweet({ businessid, tweet }).then((result) => {
                if (result && result.data && result.data.success) {
                    resolve();
                } else {
                    reject(new Error(result.data.message || 'Failed to send tweet.'));
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static favoriteTweet(businessid: string, tweetid: string, favoriting: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            //Get long token
            const favorite = firebase.functions().httpsCallable('favoriteOrUnfavoriteTweet');
            favorite({ businessid, tweetid, favoriting }).then((result) => {
                if (result && result.data && result.data.success) {
                    resolve();
                } else {
                    reject(new Error(result.data.message || 'Failed to toggle favorite on tweet.'));
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }

    static retweetTweet(businessid: string, tweetid: string, retweeting: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            //Get long token
            const favorite = firebase.functions().httpsCallable('retweetOrUnretweetTweet');
            favorite({ businessid, tweetid, retweeting }).then((result) => {
                if (result && result.data && result.data.success) {
                    resolve();
                } else {
                    reject(new Error(result.data.message || 'Failed to toggle retweet on tweet.'));
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }
}