import * as firebase from 'firebase';
import { CloudFunctionReturn, CreateBusinessAccount } from '../models';

export class RegistrationProvider {
    static canRegisterBusiness(email: string): Promise<CloudFunctionReturn<void>> {
        const canRegister = firebase.functions().httpsCallable('canRegisterBusiness');
        return canRegister({ email }).then((result) => {
            return result.data as CloudFunctionReturn<void>;
        }).catch((err) => {
            console.log(err);
            return { success: false, message: 'An error occurred. Please try again. If the problem persists contact support at support@partyme.io .'} as CloudFunctionReturn<void>;
        });
    }

    static createNewBusinessAccount(createModel: CreateBusinessAccount): Promise<CloudFunctionReturn<void>> {
        const createNewBusinessAccount = firebase.functions().httpsCallable('createNewBusinessAccount');
        return createNewBusinessAccount(createModel).then((result) => {
            return result.data as CloudFunctionReturn<void>;
        }).catch((err) => {
            return { success: false, message: 'An error occurred. DO NOT log in to your account. Contact support at support@partyme.io .'} as CloudFunctionReturn<void>;
        });
    }

    static reactivateBusinessAccount(businessid: string, subscriptionID: string, orderID: string): Promise<CloudFunctionReturn<void>> {
        const reactivateBusinessAccount = firebase.functions().httpsCallable('reactivateBusinessAccount');
        return reactivateBusinessAccount({ businessid, subscriptionID, orderID }).then((result) => {
            return result.data as CloudFunctionReturn<void>;
        });
    }

    static getNewBusinessIDForRegistration(): string {
        return firebase.firestore().collection('businesses').doc().id;
    }

    static validateReferralCode(referralCode: string): Promise<CloudFunctionReturn<{ isValid: boolean }>> {
        const validate = firebase.functions().httpsCallable('validateReferralCode');
        return validate({ referralCode }).then((result) => {
            return result.data as CloudFunctionReturn<{ isValid: boolean }>;
        });
    }
}