import React, { Component } from "react";
import { LogoTop } from "../../shared/LogoTop";
import { Row, Col, Jumbotron, Container, Button, Card, Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import AppContext from "../../AppContext";
import { Footer } from "../../shared/Footer";
import './InactiveSubscription.css';
import CancelSubscription from "./CancelSubscription";
import { PayPalProvider } from "../../providers/PayPalProvider";
import { toast } from "react-toastify";

interface Props extends RouteComponentProps { }

interface State {
  loading: boolean
}

export class InactiveSubscription extends Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false
    }
  }

  handleCancelSubscription = () => {
    this.setState({ loading: true });
    PayPalProvider.cancelSubscription(this.context.user.businessid).then((res) => {
      if (res.success) {
        this.props.history.push('/register');
      } else {
        toast.error(res.message);
      }
    }).finally(() => {
      this.setState({ loading: false });
    })
  }

  render() {
    return (
      <div className='h-100 w-100 d-flex flex-column'>
        <LogoTop user={this.context.user} history={this.props.history} />

        <div className='d-flex flex-grow-1 align-items-center justify-content-center'>
          {this.state.loading &&
            <div className='text-center'>
              <Spinner as='span' animation='border' />
            </div>
          }
          {!this.state.loading && <CancelSubscription onCancel={this.handleCancelSubscription} />}
        </div>
        <Footer />
      </div>
    );
  }
}