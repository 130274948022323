import React, { Component } from "react";

import { Button, Card, Col, Row } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { RouteComponentProps } from "react-router";
import AppContext from "../../AppContext";

interface Props extends RouteComponentProps { }

interface State { }

export class NotFound extends Component<Props, State> {
  static contextType = AppContext;

  render() {
    return (
      <>
        <Card className='shadow p-3 m-3'>
          <h4>Page Not Found</h4>
          <span>Hmmmm, that page does't seem to exist. Try using the browser's back button.</span>
          <Row>
            <Col md='4' lg='3'>
              <Button block onClick={() => this.props.history.replace('/Dashboard')}><FaHome size={20} /> Back to Safety</Button>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}