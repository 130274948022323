import React, { Component } from 'react';
import './Shared.css';
import logo from '../assets/images/icon.png';
import COLORS from './static/Colors';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import { User } from '../models';
import { AuthProvider } from '../providers/AuthProvider';

interface Props {
  showLogin?: boolean,
  showLogout?: boolean,
  user?: User,
  history?: any
}

export class LogoTop extends Component<Props> {

  handleBusinessLoginClick = () => {
    if (this.props.user) {
      this.props.history.push('/Dashboard')
    } else {
      this.props.history.push('/Login')
    }
  }

  handleLogoutClick = () => {
    AuthProvider.signOut();
  }

  render() {
    if (this.props.showLogin || (this.props.showLogout && this.props.user)) {
      return (
        <div style={{ backgroundColor: COLORS.PRIMARY }}>
          <Container>
            <Row>
              <Col onClick={() => this.props.history.push('/')}>
                <div onClick={() => this.props.history.push('/')} style={{ cursor: 'pointer' }}>
                  <img className='d-inline-block' src={logo} />
                  <h3 className='m-0 align-middle name d-none d-sm-inline-block'>PartyMe</h3>
                </div>
              </Col>
              <Col className='d-flex justify-content-end align-items-center'>
                {this.props.showLogin && <Button className='mr-4 login shadow' onClick={this.handleBusinessLoginClick}>Business Login</Button>}
                {this.props.showLogout && this.props.user && <Button className='mr-4 login shadow' onClick={() => AuthProvider.signOut()}>Sign Out</Button>}
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      return (
        <div style={{ backgroundColor: COLORS.PRIMARY }} className='text-center'>
          <div onClick={() => this.props.history.push('/')} style={{ cursor: 'pointer' }}>
            <img className='d-inline-block' src={logo} />
            <h3 className='m-0 align-middle d-inline-block name'>PartyMe</h3>
          </div>
        </div>
      );
    }
  }
}