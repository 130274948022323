import React, { FunctionComponent } from 'react';
import { useState } from 'react';
import { Form, FormControl, FormGroup, FormGroupProps } from 'react-bootstrap';
import Utilities from '../static/Utilities';

interface CurrencyInputProps {
    label?: string,
    description?: string,
    className?: string,
    style?: React.CSSProperties,
    value?: number,
    placeholder?: string,
    disabled?: boolean,
    containerProps?: FormGroupProps,
    min?: number,
    max?: number,
    precision?: number,
    currencyCode?: string,
    cultureFormat?: string,
    onChange: (value: number | null) => void,
    onBlur?: React.FocusEventHandler,
    onFocus?: React.FocusEventHandler
}

const CurrencyInput: FunctionComponent<CurrencyInputProps> = (props) => {

    const [displayedValue, setDisplayedValue] = useState(props.value?.toString() || '');
    const [numericValue, setNumericValue] = useState(props.value); // This is necessary to allow the component to be unconrolled

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value && !isNaN(Number(e.target.value.replace(',', '.')))) {

            let num = Number(e.target.value.replace(',', '.'));

            // Handle min / max
            if ((props.max !== undefined && num > props.max) || (props.min !== undefined && num < props.min))
                return;

            // Handle precision
            if (props.precision !== undefined && e.target.value.includes('.')) {
                let split = e.target.value.split('.');
                if (split[split.length - 1].length > props.precision)
                    return;
            }

            setDisplayedValue(e.target.value);
            setNumericValue(num);
            props.onChange(num);

        } else if (e.target.value === '.' || e.target.value === ',' || e.target.value === '+' || e.target.value === '-' || e.target.value === '+.' || e.target.value === '-.' || e.target.value === '+,' || e.target.value === '-,') {
            // Don't call onChange since there isn't a number yet
            setDisplayedValue(e.target.value);
        } else if (!e.target.value) {
            setDisplayedValue('');
            setNumericValue(undefined);
            props.onChange(null);
        }
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if (props.onFocus)
            props.onFocus(e);
        if (numericValue)
            setDisplayedValue(numericValue.toString());
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (props.onBlur)
            props.onBlur(e);
        if (numericValue)
            setDisplayedValue(Utilities.formatCurrency(numericValue, props.cultureFormat, props.currencyCode, props.precision, props.precision ?? 2));
    }

    return (
        <FormGroup {...props.containerProps} >

            <Form.Label>{props.label}</Form.Label>

            <FormControl className={props.className} placeholder={props.placeholder} disabled={props.disabled} onBlur={handleBlur} onFocus={handleFocus} value={displayedValue} as='input' type='text' onChange={handleChange} />

            {props.description ? <Form.Text>{props.description}</Form.Text> : null}

        </FormGroup>
    );
}

export default CurrencyInput;