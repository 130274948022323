import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { AccountRegistration } from '../../models';
import { Email } from '../../shared/inputs/Email';
import { Password } from '../../shared/inputs/Password';
import { Textbox } from '../../shared/inputs/Textbox';

interface Props {
  show: boolean,
  // onBack: () => void,
  onSubmitInformation: (info: AccountRegistration) => void
}

interface State extends AccountRegistration {

}

export default class AccountInformation extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      // businessName: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      referralCode: ''
    }
  }

  handleSubmit = () => {
    if (!this.state.email || !this.state.firstName || !this.state.lastName || !this.state.password){
      toast.error('Please fill out all fields.');
      return;
    }

    if (!this.state.email.includes('@') || !this.state.email.includes('.')){
      toast.error('Invalid email address.');
    }

    if (this.state.password.length < 8) {
      toast.error('Password must be at least 8 characters long.');
    }

    this.props.onSubmitInformation(this.state);
  }

  render() {
    return (
      <div className={!this.props.show ? 'd-none' : ''} style={{ width: 600, maxWidth: '100%' }} >
        <Card className='shadow mx-3 mb-3 mw-100'>
          <span className='card-title'>Account Information</span>
          <div className='p-3'>
            <p className='text-center'>The following information will be used to create your account. <br /> <strong>Please verify it is correct before continuing.</strong></p>
            <br />
            {/* <Textbox label='Company Name' value={this.state.businessName} onTextChange={(val) => this.setState({ businessName: val })} /> */}
            <Row>
              <Col sm xs='12'>
                <Textbox label='First Name' value={this.state.firstName} onTextChange={(val) => this.setState({ firstName: val })} />
              </Col>
              <Col sm xs='12'>
                <Textbox label='Last Name' value={this.state.lastName} onTextChange={(val) => this.setState({ lastName: val })} />
              </Col>
            </Row>
            <Email label='Email Address' value={this.state.email} onTextChange={(val) => this.setState({ email: val })} />
            <Password label='Password' value={this.state.password} onEnterPressed={this.handleSubmit} onTextChange={(val) => this.setState({ password: val })} />
            <hr />
            <Textbox label='Referral Code' onEnterPressed={this.handleSubmit} description={'Optional - If given a referral code, please enter it here.'} value={this.state.referralCode} onTextChange={(val) => this.setState({ referralCode: val })} />
          </div>
        </Card>
        <Row className='mx-1'>
          {/* <Col>
            <Button onClick={this.props.onBack} className='shadow' block variant='light'><FaChevronLeft /> Back</Button>
          </Col> */}
          <Col>
            <Button onClick={this.handleSubmit} className='shadow' block>Continue <FaChevronRight /></Button>
          </Col>
        </Row>
      </div>
    );
  }
}