import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  value: string,
  label: string,
  error?: boolean,
  placeholder?: string,
  height?: number | string,
  disabled?: boolean,
  className?: string,
  maxLength?: number,
  onTextChange: (value) => void
}

export class Textarea extends Component<Props> {

  render() {
    return (
      <Form.Group className={this.props.className}>
        {this.props.label !== null ? <Form.Label>{this.props.label}</Form.Label> : null}
        <Form.Control disabled={this.props.disabled} maxLength={this.props.maxLength} style={{ minHeight: `${this.props.height || 50}px` }} as='textarea' className={this.props.error ? 'error' : ''} type='text' value={this.props.value} placeholder={this.props.placeholder} onChange={(e) => this.props.onTextChange(e.target.value)} />
      </Form.Group>
    );
  }
}