import React, { Component } from "react";
import { LogoTop } from "../../shared/LogoTop";
import { Row, Col, Jumbotron, Container, Button } from "react-bootstrap";
import eventImage from '../../assets/images/event.png';
import eventsImage from '../../assets/images/events.png';
import friendsImage from '../../assets/images/friends.png';
import chatImage from '../../assets/images/chat.png';
import partiesImage from '../../assets/images/parties.png';
import phoneImage from '../../assets/images/phone.png';
import { RouteComponentProps } from "react-router-dom";
import AppContext from "../../AppContext";
import COLORS from "../../shared/static/Colors";
import { FaAppStore, FaGooglePlay } from "react-icons/fa";
import { Footer } from "../../shared/Footer";

import './Home.css';

interface Props extends RouteComponentProps { }

interface State { }

export class Home extends Component<Props, State> {
  static contextType = AppContext;

  handleGooglePlayClick = () => {
    window.open('https://play.google.com/store/apps/details?id=llc.attebyte.partyme', '_blank');
  }

  handleAppStoreClick = () => {
    window.open('https://testflight.apple.com/join/uLaBZwod', '_blank');
  }

  render() {
    return (
      <div style={{ backgroundColor: '#f7f7f7'}}>
        <LogoTop showLogin user={this.context.user} history={this.props.history} />
        <div style={{ backgroundColor: COLORS.WHITE }} className='shadow' >
          <Container className='pb-1 pb-md-0'>
            <Jumbotron style={{ backgroundColor: COLORS.WHITE }} className='pb-0'>
              <Row>
                <Col>
                  <p style={{ fontSize: 40 }}>
                    The easiest way to coordinate a night on the town.
                  </p>
                  <Button onClick={this.handleGooglePlayClick} ><FaGooglePlay /> Google Play</Button>
                  <Button onClick={this.handleAppStoreClick} className='ml-3'><FaAppStore /> App Store</Button>
                </Col>
                <Col className='d-md-flex d-none justify-content-center'>
                  <img src={phoneImage} height={300} />
                </Col>
              </Row>
            </Jumbotron>
          </Container>
        </div>
        <div>
          <Row noGutters className='mb-3 mt-4 mt-md-0 info-row' >
            <Col md xs='12' className='d-flex align-items-center justify-content-center mr-md-4'>
              <img src={eventsImage} width={350}  style={{ maxWidth: '96%' }} className='rounded shadow' />
            </Col>
            <Col md xs='12' className='d-flex align-items-center text-center justify-content-center ml-md-4'>
              <div style={{ width: '500px' }}>
                <h2>Find Events</h2>
                <span style={{ fontSize: '20px' }}>
                  PartyMe allows you to search for public events that are being hosted by local businesses.
                  You can easily share these events with your friends or a group.
                </span>
              </div>
            </Col>
          </Row>
          <Row noGutters className='my-3 flex-md-row-reverse bg-white info-row' >
            <Col md xs='12' className='d-flex align-items-center justify-content-center ml-md-4'>
              <img src={partiesImage} width={350} style={{ maxWidth: '96%' }} className='rounded shadow' />
            </Col>
            <Col md xs='12' className='d-flex align-items-center justify-content-center text-center mr-md-4'>
              <div style={{ width: '500px' }}>
                <h2>Plan Parties</h2>
                <span style={{ fontSize: '20px' }}>
                  Businesses aren't the only ones able to plan parties! Coordinate any kind of get-together through PartyMe. With a dedicated chat group for everyone
                  invited, it's easier than ever to communicate before and after.
                </span>
              </div>
            </Col>
          </Row>
          <Row noGutters className='my-3 info-row' >
            <Col md xs='12' className='d-flex align-items-center justify-content-center mr-md-4'>
              <img src={friendsImage} width={400} style={{ maxWidth: '96%' }} className='rounded shadow' />
            </Col>
            <Col md xs='12' className='d-flex align-items-center text-center justify-content-center ml-md-4'>
              <div style={{ width: '500px' }}>
                <h2>Add Friends</h2>
                <span style={{ fontSize: '20px' }}>
                  Meet someone new at that local party? Make it easy to meet up again by adding them as a friend. You can add anyone by clicking their profile in a chat,
                  scanning their unique QR code in the app, or entering their username directly.
                </span>
              </div>
            </Col>
          </Row>
          <Row noGutters className='mt-3 flex-md-row-reverse bg-white info-row' >
            <Col md xs='12' className='d-flex align-items-center justify-content-center ml-md-4'>
              <img src={chatImage} width={400} style={{ maxWidth: '96%' }} className='rounded shadow' />
            </Col>
            <Col md xs='12' className='d-flex align-items-center justify-content-center text-center mr-md-4'>
              <div style={{ width: '500px' }}>
                <h2>Chat in Groups or Privately</h2>
                <span style={{ fontSize: '20px' }}>
                  Just like any other chat app, you can create groups of friends to chat with.
                  You can also speak to them directly through private chats.
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}