import React, { Component } from 'react';
import app from 'firebase/app';
import { User, FacebookToken } from '../../models';
import { RouteComponentProps } from 'react-router-dom';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import AppContext from '../../AppContext';
import { LoginButton } from 'react-facebook';
import { FaFacebook, FaSave, FaExternalLinkAlt, FaTwitter } from 'react-icons/fa';
import { Card, Col, Row, ListGroup, Button, Spinner } from 'react-bootstrap';
import { Radio } from '../../shared/inputs/Radio';
import { UserProvider } from '../../providers/UserProvider';
import { FacebookProvider } from '../../providers/FacebookProvider';
import { toast } from 'react-toastify';
import { TwitterProvider } from '../../providers/TwitterProvider';

interface Props {
  onLinkComplete: (token: FacebookToken) => void
}

interface State {
  twitterAuthURL: string,
  loading: boolean
}

export class LinkTwitter extends Component<Props, State>{
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);

    this.state = {
      twitterAuthURL: null,
      loading: true
    }
  }

  componentDidMount() {
    this.getTwitterAuthURL();
  }

  getTwitterAuthURL = () => {
    TwitterProvider.getAuthURL(this.context.user.businessid).then((url) => {
      this.setState({ twitterAuthURL: url });
    }).catch((err) => {
      toast.error(err.message);
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  handleLoginClick = () => {
    window.open(this.state.twitterAuthURL, '_blank');
  }

  render() {
    return (
      <Card className='m-3 p-3 shadow'>
        <h5>Link Twitter Account</h5>
        <span>
          In order to access and manage your Twitter account, you must sign in and allow access. <br />
          Please click the button below to continue.
        </span>
        <Button variant='clear' className='twitter-btn mt-3' disabled={!this.state.twitterAuthURL} block onClick={this.handleLoginClick}>{this.state.loading ? <Spinner as='span' size='sm' animation='border' /> : <FaTwitter size={20} />} Sign In With Twitter</Button>
      </Card>
    );
  }
}