import React, { Component } from "react";

import { Card } from "react-bootstrap";
import AppContext from "../../AppContext";

interface Props { }

interface State { }

export class Error extends Component<Props, State> {
  static contextType = AppContext;

  render() {
    return (
      <>
        <Card className='shadow p-3 m-3'>
          <h4>Error</h4>
          <span>Something went wrong. Please go back and try the action again.</span>
        </Card>
      </>
    );
  }
}