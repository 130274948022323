import React from 'react';
import { FaBars } from 'react-icons/fa';
import COLORS from '../shared/static/Colors';
import { PartyHatIcon } from '../shared/static/Icons';

interface Props {
  show: boolean,
  title: string,
  onToggle: () => void
}

const PageHeader = (props: Props) => {
  if (!props.show)
    return null;

  return (
    <div className='w-100'>
      <FaBars onClick={props.onToggle} style={{ cursor: 'pointer', marginBottom: '14px' }} size={30} color={COLORS.GRAY} />
      <span className='w-100 ml-2' style={{ fontWeight: 'bold', fontSize: 30, color: COLORS.GRAY }}>{props.title}</span>
    </div>
  );
}

export default PageHeader;