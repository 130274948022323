import React, { Component } from 'react';
import app from 'firebase/app';
import { User, FacebookToken, FacebookPost, FacebookInsights } from '../../models';
import { RouteComponentProps } from 'react-router-dom';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import AppContext from '../../AppContext';
import { LineChart, XAxis, YAxis, Line, ResponsiveContainer } from 'recharts';
import { LinkFacebook } from './LinkFacebook';

import './Facebook.css';
import { UserProvider } from '../../providers/UserProvider';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Row, Col, Card, Button, Spinner, Badge } from 'react-bootstrap';
import COLORS from '../../shared/static/Colors';
import { FaShare } from 'react-icons/fa';
import { Textarea } from '../../shared/inputs/Textarea';
import { MdSend } from 'react-icons/md';
import { BsChatFill } from 'react-icons/bs';
import { AiTwotoneLike } from 'react-icons/ai';
import { IoMdShareAlt } from 'react-icons/io';
import { FacebookProvider } from '../../providers/FacebookProvider';
import { ConfirmationAlert } from '../../shared/ConfirmationAlert';

interface Props extends RouteComponentProps {

}

interface State {
  isLoading: boolean,
  pageToken: FacebookToken,
  insights: FacebookInsights,
  recentActivity: FacebookPost[],
  status: string,
  submittingStatus: boolean,
  liking: string,
  sharing: string
}

export class Facebook extends Component<Props, State>{
  static contextType = AppContext;

  constructor(props: Props, context) {
    super(props, context);

    this.state = {
      isLoading: true,
      pageToken: null,
      insights: {
        adds: [],
        engagements: []
      },
      recentActivity: [],
      status: '',
      submittingStatus: false,
      liking: null,
      sharing: null
    };
  }

  componentDidMount() {
    if (this.context.user)
      this.getPageToken();
  }

  getPageToken = () => {
    UserProvider.getFacebookToken(this.context.user.businessid).then((token) => {
      this.setState({ pageToken: token }, () => {
        if (token) {
          this.getPageInformation();
        } else {
          this.setState({ isLoading: false });
        }
      });
    }).catch((err) => {
      toast.error('Failed to get facebook access token.');
    });
  }

  getPageInformation = () => {
    let proms = [];

    proms.push(FacebookProvider.getInsights(this.state.pageToken.pageID, this.state.pageToken.pageToken).then((insights) => {
      this.setState({ insights: insights });
    }));

    proms.push(FacebookProvider.getRecentPosts(this.state.pageToken.pageID, this.state.pageToken.pageToken).then((posts) => {
      this.setState({ recentActivity: posts });
    }));

    Promise.all(proms).then(() => {
      this.setState({ isLoading: false });
    }).catch((err) => {
      console.log(err);
      toast.error('Failed to get Facebook page information.');
    });
  }

  submitPostClick = () => {
    this.setState({ submittingStatus: true });
    FacebookProvider.submitPost(this.state.pageToken.pageID, this.state.pageToken.pageToken, this.state.status).then(() => {
      toast.success('Post submitted to Facebook!');
      this.setState({ status: '' });
    }).catch((err) => {
      toast.error(err);
    }).finally(() => {
      this.setState({ submittingStatus: false });
    });
  }

  shareClick = (postid: string, posturl: string, isShared: boolean) => {
    this.setState({ sharing: postid });
    if (isShared) {
      ConfirmationAlert.confirm('Share Post?', 'You have already shared this post before. Are you sure you want to share it again?', 'primary').then((result) => {
        if (result) {
          FacebookProvider.sharePost(this.state.pageToken.pageID, this.state.pageToken.pageToken, posturl).then(() => {
            let posts = this.state.recentActivity;
            for (let index = 0; index < posts.length; index++) {
              if (posts[index].id === postid) {
                posts[index].isShared = true;
                break;
              }
            }
            this.setState({ recentActivity: posts });
          }).catch((err) => {
            toast.error(err);
          }).finally(() => {
            this.setState({ sharing: null });
          });
        } else {
          this.setState({ sharing: null });
        }
      });
    } else {
      FacebookProvider.sharePost(this.state.pageToken.pageID, this.state.pageToken.pageToken, posturl).then(() => {
        let posts = this.state.recentActivity;
        for (let index = 0; index < posts.length; index++) {
          if (posts[index].id === postid) {
            posts[index].isShared = true;
            break;
          }
        }
        this.setState({ recentActivity: posts });
      }).catch((err) => {
        toast.error(err);
      }).finally(() => {
        this.setState({ sharing: null });
      });
    }
  }

  likeClick = (postid: string, liking: boolean) => {
    this.setState({ liking: postid });
    FacebookProvider.likeOrUnlikePost(postid, liking, this.state.pageToken.pageToken).then(() => {
      let posts = this.state.recentActivity;
      for (let index = 0; index < posts.length; index++) {
        if (posts[index].id === postid) {
          posts[index].isLiked = liking;
          break;
        }
      }
      this.setState({ recentActivity: posts });
    }).catch((err) => {
      toast.error(err);
    }).finally(() => {
      this.setState({ liking: null });
    })
  }

  onLinkComplete = (token: FacebookToken) => {
    this.setState({ pageToken: token }, () => {
      this.getPageInformation();
    });
  }

  render() {
    return (
      <>
        <LoadingIndicator show={this.state.isLoading} />
        {!this.state.pageToken && !this.state.isLoading && <LinkFacebook onLinkComplete={this.onLinkComplete} />}

        {this.state.pageToken && !this.state.isLoading &&
          <div>
            <div className='p-3'>
              <Row>
                <Col className='pl-0 pr-0 pr-md-3' md='6' xs='12'>
                  <Card className='shadow p-3'>
                    <h3>Post Engagements</h3>
                    <ResponsiveContainer minHeight={300}>
                      <LineChart data={this.state.insights.engagements}>
                        <XAxis dataKey='label' />
                        <YAxis dataKey='value' width={40} />
                        <Line dot type='linear' dataKey='value' stroke={COLORS.PRIMARY} strokeWidth={3} strokeOpacity={1} />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
                <Col className='pr-0 pl-0 pl-md-3 mt-3 mt-md-0' md='6' xs='12'>
                  <Card className='shadow p-3'>
                    <h3>Page Follows</h3>
                    <ResponsiveContainer minHeight={300}>
                      <LineChart data={this.state.insights.adds}>
                        <XAxis dataKey='label' />
                        <YAxis dataKey='value' width={40} />
                        <Line dot type='linear' dataKey='value' stroke={COLORS.PRIMARY} strokeWidth={3} strokeOpacity={1} />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                </Col>
              </Row>
            </div>
            <Card className='mt-3 p-3 shadow'>
              <h3>Create Post</h3>
              <Textarea height={100} placeholder='Write something...' label={null} value={this.state.status} onTextChange={(val) => this.setState({ status: val })} />
              <Row className='flex-row-reverse'>
                <Col lg='3' md='4' xs='12'>
                  <Button disabled={this.state.submittingStatus} block onClick={this.submitPostClick}>{this.state.submittingStatus ? <Spinner as='span' size='sm' animation='border' /> : <MdSend size={20} />} Submit</Button>
                </Col>
              </Row>
            </Card>
            <Card className='shadow p-3 mt-4'>
              <h3>Recent Activity</h3>
              <div>
                {this.state.recentActivity && this.state.recentActivity.length ?

                  this.state.recentActivity.map(x => {
                    return (
                      <Card key={x.id} className='shadow p-3 my-2'>
                        <h5>{x.from} <div style={{ color: '#828282', fontSize: '10px' }} className='d-inline-block'>{x.date}</div></h5>
                        <p>{x.message}</p>
                        <Row>
                          <Col className='text-right mt-2'>
                            <Button size='sm' className='position-relative'><BsChatFill size={20} /><Badge className='position-absolute' style={{ top: -7, right: -7 }} variant='dark'>{x.commentCount}</Badge></Button>
                            <Button size='sm' className='position-relative mx-3' onClick={() => this.shareClick(x.id, x.postURL, x.isShared)} title={'Share'} variant={x.isShared ? 'danger' : 'primary'} disabled={this.state.sharing === x.id}>{this.state.sharing === x.id ? <Spinner as='span' animation='border' size='sm' /> : <IoMdShareAlt size={20} />}<Badge className='position-absolute' style={{ top: -7, right: -7 }} variant='dark'>{x.shareCount}</Badge></Button>
                            <Button size='sm' className='position-relative' onClick={() => this.likeClick(x.id, !x.isLiked)} title={x.isLiked ? 'Unlike' : 'Like'} variant={x.isLiked ? 'danger' : 'primary'} disabled={this.state.liking === x.id}>{this.state.liking === x.id ? <Spinner as='span' animation='border' size='sm' /> : <AiTwotoneLike size={20} />}<Badge className='position-absolute' style={{ top: -7, right: -7 }} variant='dark'>{x.likeCount}</Badge></Button>
                          </Col>
                        </Row>
                      </Card>
                    );
                  })

                  :

                  'No Recent Activity'

                }
              </div>
            </Card>
          </div>
        }
      </>
    );
  }
}