import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaCheckSquare, FaChevronRight } from 'react-icons/fa';
import COLORS from '../../shared/static/Colors';

import logo from '../../assets/images/icon.png';

interface Props {
  show: boolean,
  price: number,
  onContinue: () => void
}

interface State { }

export default class Overview extends React.Component<Props, State> {

  render() {
    return (
      <div className={!this.props.show ? 'd-none' : ''} style={{ width: 600, maxWidth: '100%' }}>
        <Card className='shadow mb-3  mx-3' style={{ maxWidth: '100%' }}>
          <span className='card-title'>Subscription Overview</span>
          {/* <div className='w-100 text-center rounded' style={{ backgroundColor: COLORS.PRIMARY }}>
            <img height={70} className='mx-auto' src={logo} />
          </div> */}
          <div className='p-2 font-weight-bold'>
            <Row className='border-bottom' style={{ height: '30px' }}>
              <Col xs='10'>
                PartyMe Portal Access
              </Col>
              <Col className='text-right'>
                <FaCheckSquare size={20} color={COLORS.PRIMARY} />
              </Col>
            </Row>
            <Row className='border-bottom' style={{ height: '30px' }}>
              <Col xs='10'>
                Event Statistics Reporting
              </Col>
              <Col className='text-right'>
                <FaCheckSquare size={20} color={COLORS.PRIMARY} />
              </Col>
            </Row>
            <Row className='border-bottom' style={{ height: '30px' }}>
              <Col xs='10'>
                Troublshooting Support
              </Col>
              <Col className='text-right'>
                <FaCheckSquare size={20} color={COLORS.PRIMARY} />
              </Col>
            </Row>
            <Row className='border-bottom' style={{ height: '30px' }}>
              <Col xs='10'>
                Facebook Integration
              </Col>
              <Col className='text-right'>
                <FaCheckSquare size={20} color={COLORS.PRIMARY} />
              </Col>
            </Row>
            <Row className='border-bottom' style={{ height: '30px' }}>
              <Col xs='10'>
                Twitter Integration
              </Col>
              <Col className='text-right'>
                <FaCheckSquare size={20} color={COLORS.PRIMARY} />
              </Col>
            </Row>
            <Row className='border-bottom' style={{ height: '30px' }}>
              <Col xs='10'>
                1 Month Free Trial
              </Col>
              <Col className='text-right'>
                <FaCheckSquare size={20} color={COLORS.PRIMARY} />
              </Col>
            </Row>
            <p className='text-center w-100' style={{ fontSize: '40px', paddingLeft: '20px' }}>${this.props.price} <span style={{ fontSize: '10px', color: COLORS.GRAY }}>/mo</span></p>
          </div>
        </Card>
        <Row className='mx-1'>
          {/* <Col>
            <Button onClick={this.props.onBack} className='shadow' block variant='light'><FaChevronLeft /> Back</Button>
          </Col> */}
          <Col>
            <Button onClick={this.props.onContinue} className='shadow' block>Get Started <FaChevronRight /></Button>
          </Col>
        </Row>
      </div>
    );
  }
}