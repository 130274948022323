import { OnApproveData } from '@paypal/paypal-js/types/components/buttons';
import { PayPalButtons } from '@paypal/react-paypal-js';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import AppContext from '../../AppContext';
import { AccountRegistration, BusinessRegistration, SubscriptionTier } from '../../models';
import PAYPAL_CONFIG from '../../PayPalConfig';
import Address from '../../shared/Address';

interface Props {
  show: boolean,
  data: OnApproveData,
  price: number,
  accountInfo: AccountRegistration,
  businessInfo: BusinessRegistration,
  onLoginClick: () => void,
  onDashboardClick: () => void
}

interface State extends BusinessRegistration {

}

export default class Complete extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    // this.state = {

    // }
  }

  render() {
    if (!this.props.data)
      return null;

    return (
      <div className={!this.props.show ? 'd-none' : ''} style={{ width: 600, maxWidth: '100%' }}>
        <Card className={'shadow m-3 mw-100'}>
          <span className='card-title'>Thanks!</span>
          <div style={{ fontSize: 18 }} className='p-3'>
            <strong style={{ fontSize: 22 }}>Order Number: {this.props.data.orderID}</strong>
            <hr />
            <strong style={{ fontSize: 22 }}>Order Details</strong> <br />
            ${this.props.price} /mo <br />
            1 Month Free Trial
            <hr />
            {this.props.accountInfo &&
              <>
                <strong style={{ fontSize: 22 }}>Account Information</strong> <br />
                {this.props.accountInfo.firstName} {this.props.accountInfo.lastName} <br />
                {this.props.accountInfo.email} <br />
                <strong style={{ fontSize: 22 }}>Business Information</strong> <br />
                {this.props.businessInfo.name} <br />
                <Address {...this.props.businessInfo} />
                <hr />
                <p className='text-center'>Your account is ready to go! Use the email listed above with the password from the Account Information step.</p>
                <Button onClick={this.props.onLoginClick} block className='mt-2'>Login</Button>
              </>
            }
            {!this.props.accountInfo &&
              <>
                <p className='text-center'>Your account has been reactivated!</p>
                <Button onClick={this.props.onDashboardClick} block className='mt-2'>Dashboard</Button>
              </>
            }
          </div>
        </Card>
      </div>
    );
  }
}