import React, { Component } from 'react';
import icon from '../assets/images/icon.png';
import { MdChatBubble, MdDashboard, MdDateRange, MdSettings } from 'react-icons/md';
import { Nav } from 'react-bootstrap';
import { IoMdLogOut } from 'react-icons/io';
import { GrFacebook } from 'react-icons/gr';
import { FaTwitterSquare, FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';
import './Layout.css';
import { Link, NavLink } from 'react-router-dom';
import { AuthProvider } from '../providers/AuthProvider';
import AppContext from '../AppContext';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { PartyHatIcon } from '../shared/static/Icons';
import COLORS from '../shared/static/Colors';

interface Props {
  open: boolean,
  show: boolean
}

export class SideNav extends Component<Props> {
  static contextType = AppContext;

  handleLogoutClick = () => {
    AuthProvider.signOut();
  }

  render() {
    if (!this.props.show)
      return null;

    return (
      <Nav style={{ minWidth: this.props.open ? '275px' : '56px' }} className={'flex-column sidebar bg-white shadow' + (!this.props.open ? ' closed' : '')}>
        <Nav.Item className='p-3' as={NavLink} to='/dashboard'>
          <PartyHatIcon className='mr-3 ml-1' color={COLORS.GRAY} height={30} />
          <span style={{ marginTop: '2px' }}>Dashboard</span>
        </Nav.Item>
        <Nav.Item className='p-3' as={NavLink} to='/events'>
          <MdDateRange className='mr-3' size={25} color={COLORS.GRAY} />
          <span style={{ marginTop: '2px' }}>Event Schedule</span>
        </Nav.Item>
        <Nav.Item className='p-3' as={NavLink} to='/facebook'>
          <FaFacebookSquare className='mr-3' size={25} color={COLORS.GRAY} />
          <span style={{ marginTop: '2px' }}>Facebook</span>
        </Nav.Item>
        <Nav.Item className='p-3' as={NavLink} to='/twitter'>
          <FaTwitterSquare className='mr-3' size={25} color={COLORS.GRAY} />
          <span style={{ marginTop: '2px' }}>Twitter</span>
        </Nav.Item>
        <Nav.Item className='p-3' as={NavLink} to='/settings'>
          <MdSettings className='mr-3' size={25} color={COLORS.GRAY} />
          <span style={{ marginTop: '2px' }}>Settings</span>
        </Nav.Item>
        <Nav.Item className='p-3' as={NavLink} to='/feedback'>
          <MdChatBubble className='mr-3' size={25} color={COLORS.GRAY} />
          <span style={{ marginTop: '2px' }}>Feedback</span>
        </Nav.Item>
        <Nav.Item className='p-3 mt-4' onClick={this.handleLogoutClick}>
          <IoMdLogOut className='mr-3' size={25} color={COLORS.GRAY} />
          <span style={{ marginTop: '2px' }}>Log Out</span>
        </Nav.Item>
      </Nav>
    );
  }
}