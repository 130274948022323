import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { User, Context } from '../models';
import AppContext from '../AppContext';
import { SideNav } from './SideNav';
import { PageContainer } from './PageContainer';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { FacebookProvider } from 'react-facebook'
import NavToggle from './NavToggle';
import PageHeader from './PageHeader';
import { FaTimes } from 'react-icons/fa';
import COLORS from '../shared/static/Colors';

interface Props extends RouteComponentProps {
  appLoading: boolean,
  showNav: boolean,
  pageTitle?: string,
  user: User
}

interface State {
  navOpen: boolean
}

export class Layout extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      navOpen: false
    };
  }

  render() {
    const contextValue: Context = {
      user: this.props.user
    };

    return (
      <AppContext.Provider value={contextValue}>
        <LoadingIndicator show={this.props.appLoading} />
        <FacebookProvider appId='3038859409563116'>
          <div className='d-flex overflow-none h-100'>
            {this.state.navOpen &&
              <div onClick={() => this.setState({ navOpen: false })} className='sidebar-cover'>
                <FaTimes size={30} color={COLORS.WHITE} />
              </div>
            }
            <SideNav show={this.props.showNav} open={this.state.navOpen} />
            <div className={'flex-grow-1 overflow-auto' + (this.props.showNav ? ' p-3' : '')}>
              <PageHeader onToggle={() => this.setState({ navOpen: !this.state.navOpen })} show={this.props.showNav} title={this.props.pageTitle} />
              {this.props.children}
            </div>
          </div>
        </FacebookProvider>
      </AppContext.Provider>
    );
  }
}