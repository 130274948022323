import firebase, { firestore } from "firebase";

export type CloudFunctionReturn<T> = T & {
  success?: boolean,
  message?: string
}

export interface Context {
  user?: User
}

export interface User {
  id: string,
  businessid: string,
  name: string,
  isAdmin: boolean,
  email: string,
  password?: string //This is only returned once when a new user is created
}

export interface Settings {
  // defaultLocationID: string,
  defaultAllowChat: boolean,
  promptSocialMediaOnEventSave: boolean
}

export interface Business {
  id: string,
  name: string,
  thumbnail?: string,
  location: Location,
  settings?: Settings,
  userids: string[],
  tier: string,
  subscriptionid?: string,
  activeuntil?: firestore.Timestamp
}

export interface BusinessRegistration extends Address {
  name: string
}

export interface AccountRegistration {
  // businessName: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  referralCode: string
}

export interface SubscriptionTier {
  name: string,
  maxLocations: number,
  price: number,
  trialMonths: number
}

export enum SUBSCRIPTION_TIER {
  'Testing',
  'Silver',
  'Gold',
  'Diamond'
}

export const SUBSCRIPTION_TIERS: {
  'Silver': SubscriptionTier,
  'Gold': SubscriptionTier,
  'Diamond': SubscriptionTier
} = {
  'Silver': {
    name: 'Silver',
    maxLocations: 3,
    price: 25,
    trialMonths: 2
  },
  'Gold': {
    name: 'Gold',
    maxLocations: 7,
    price: 30,
    trialMonths: 2
  },
  'Diamond': {
    name: 'Diamond',
    maxLocations: 25,
    price: 50,
    trialMonths: 3
  }
}

export interface ModelBase {
  success?: boolean;
  message?: string;
}

export interface CreateBusinessAccount {
  newBusinessID: string,
  companyName: string,
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  street1: string,
  street2: string,
  city: string,
  state: string,
  postalCode: string,
  latitude: number,
  longitude: number,
  geohash: string,
  orderID: string,
  paymentID: string,
  subscriptionID: string,
  referralCode: string
}

export interface FacebookToken {
  pageID: string,
  pageToken: string
}

export interface TwitterToken {
  accessToken: string,
  accessTokenSecret: string
}

export interface PostQueue {
  id: string,
  eventid: string,
  platform: 'facebook' | 'twitter',
  postdate: firebase.firestore.Timestamp,
  businessid: string,
  content: string
}

export interface Application {
  firstName: string,
  lastName: string,
  phoneNumber: string,
  email: string,
  jobTitle: string,
  businessName: string,
  numberOfEmployees: string,
  numberOfLocations: number,
  street1: string,
  street2: string,
  city: string,
  state: string,
  postalCode: number,
}

export interface Address {
  street1: string,
  street2?: string,
  city: string,
  state: string,
  postalCode: string
}

export interface Geo {
  geohash?: string,
  latitude?: number,
  longitude?: number
}

export interface Event extends Geo, ModelBase {
  id?: string,
  name: string,
  businessID: string,
  businessName: string,
  thumbnail: string,
  description?: string,
  masksRequired?: boolean,
  coverCharge?: number,
  startDate: Date,
  endDate: Date,
  chatid?: string
}

interface EventStatBase {
  views: number,
  interactions: number,
  attendees: number
}

export interface FollowStat {
  date: number,
  follows: number,
  unfollows: number
}

export interface EventStat extends EventStatBase {
  eventid: string
}

export interface AggregateStat extends EventStatBase {
  date: number,
}

export interface Location extends ModelBase, Address, Geo { }

export interface PublicEvent extends Event {
  businessid: string
}

export interface BooleanResult extends ModelBase {
  value: boolean
}

export interface GraphData {
  value: number,
  label: string
}

export interface FacebookInsights {
  adds: GraphData[],
  engagements: GraphData[]
}

export interface FacebookComment {
  id: string,
  message: string,
  from: string,
  fromID: string
}

export interface FacebookPost {
  id: string,
  from: string,
  fromID: string,
  message: string,
  comments: FacebookComment[],
  commentCount: number,
  shareCount: number,
  likeCount: number,
  isLiked: boolean,
  isShared: boolean,
  date: string,
  postURL: string
}

export interface TwitterTweet {
  id: string,
  from: string,
  fromUsername: string,
  date: string,
  message: string,
  retweeted: boolean,
  favorited: boolean,
  retweetCount: number,
  favoriteCount: number,
  comments: TwitterTweet[],
  commentCount: number
}