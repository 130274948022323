import React, { Component, ReactElement } from 'react';
import { AggregateStat, Event, EventStat, FollowStat, PostQueue } from '../../models';
import { Link, RouteComponentProps } from 'react-router-dom';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { EventProvider } from '../../providers/EventProvider';
import AppContext from '../../AppContext';
import { StatisticsProvider } from '../../providers/StatisticsProvider';
import { Button, Card, Col, ListGroup, Row } from 'react-bootstrap';
import StatCard from './StatCard';
import { toast } from 'react-toastify';
import { GoDash } from 'react-icons/go';
import { ImArrowDown, ImArrowUp } from 'react-icons/im';
import COLORS from '../../shared/static/Colors';
import { ResponsiveContainer, Line, XAxis, YAxis, LineChart, Tooltip } from 'recharts';
import { format } from 'date-fns';
import { resolve } from 'url';
import { FaExternalLinkAlt, FaExternalLinkSquareAlt, FaFacebook, FaLink, FaPlus, FaTrash, FaTrashAlt, FaTwitter } from 'react-icons/fa';
import { FacebookProvider } from '../../providers/FacebookProvider';
import { UserProvider } from '../../providers/UserProvider';
import { PostQueueModal } from '../../shared/PostQueueModal';
import { ConfirmationAlert } from '../../shared/ConfirmationAlert';

interface Props extends RouteComponentProps {

}

enum Performance {
  OUTPERFORM,
  UNDERPERFORM,
  NOT_AVAILABLE
}

interface State {
  isLoading: boolean,
  numberOfScheduled: number,
  showNextEvent: boolean,
  nextEvent: Event,
  lastStat: EventStat,
  lastOutperform: Performance,
  events: Event[],
  aggregateStats: AggregateStat[],
  followStats: FollowStat[],
  postQueues: PostQueue[],
  hasFacebookToken: boolean,
  hasTwitterToken: boolean,
  showPostQueueModal: boolean
}

export class Dashboard extends Component<Props, State>{
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      showNextEvent: false,
      nextEvent: null,
      numberOfScheduled: 0,
      lastStat: null,
      lastOutperform: Performance.NOT_AVAILABLE,
      events: [],
      aggregateStats: [],
      followStats: [],
      postQueues: [],
      hasFacebookToken: false,
      hasTwitterToken: false,
      showPostQueueModal: false
    }
  }

  componentDidMount() {
    if (this.context.user) {
      let eventPromise = EventProvider.getEvents(this.context.user.businessid).then((results) => {

        let next: Event;
        let sortedNext = results.filter(x => x.startDate.getTime() > Date.now()).sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
        if (sortedNext.length > 0)
          next = sortedNext[0];

        let last: Event;
        let sortedLast = results.filter(x => x.endDate.getTime() < Date.now()).sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
        if (sortedLast.length > 0) {
          last = sortedLast[sortedLast.length - 1];
        }

        this.setState({ events: results, nextEvent: next, numberOfScheduled: sortedNext.length });

        if (sortedLast.length < 1)
          return;

        return StatisticsProvider.getEventStat(this.context.user.businessid, last.id).then((lastStat) => {
          return new Promise<void>((resolve) => {
            this.setState({ lastStat }, () => {
              resolve();
            })
          });
        }).catch((err) => {
          //Eat the error here
        });
      });

      let aggPromise = StatisticsProvider.getAggregateStats(this.context.user.businessid).then((results) => {
        return new Promise<void>((resolve) => {
          this.setState({ aggregateStats: results }, () => {
            resolve();
          })
        });
      });

      let folPromise = StatisticsProvider.getFollowStats(this.context.user.businessid).then((results) => {
        return new Promise<void>((resolve) => {
          this.setState({ followStats: results }, () => {
            resolve();
          })
        });
      });

      let postPromise = EventProvider.getEventPostQueue(this.context.user.businessid).then((queues) => {
        return new Promise<void>((resolve) => {
          this.setState({ postQueues: queues }, () => {
            resolve();
          });
        });
      });

      let facePromise = UserProvider.getFacebookToken(this.context.user.businessid).then((token) => {
        if (token)
          this.setState({ hasFacebookToken: true });
      });

      let twitterPromise = UserProvider.getTwitterToken(this.context.user.businessid).then((token) => {
        if (token)
          this.setState({ hasTwitterToken: true });
      });

      Promise.all([eventPromise, aggPromise, folPromise, postPromise, facePromise, twitterPromise]).then(() => {
        if (this.state.lastStat) {
          let avg = this.state.aggregateStats.reduce((a, b) => a + b.interactions, 0) / this.state.aggregateStats.length;
          this.setState({ lastOutperform: this.state.lastStat.interactions > avg ? Performance.OUTPERFORM : Performance.UNDERPERFORM });
        }


        this.setState({ isLoading: false });
      }).catch((err) => {
        console.log(err);
        toast.error('Failed to retrieve dashboard.');
      });
    }
  }

  handleQueueModalClose = (newQueues: PostQueue[]) => {
    let queues = this.state.postQueues;
    if (newQueues && newQueues.length)
      queues.push(...newQueues);

    this.setState({ postQueues: queues, showPostQueueModal: false });
  }

  handleDeletePostQueueClick = (queue: PostQueue) => {
    ConfirmationAlert.confirm('Delete Scheduled Post?', `Are you sure you want to cancel this post to ${queue.platform}?`, 'danger').then((res) => {
      if (res) {
        EventProvider.deletePostQueue(queue.id).then(() => {
          toast.success('Post cancelled.');
          let queues = this.state.postQueues;
          queues = queues.filter(x => x.id !== queue.id);
          this.setState({ postQueues: queues });
        }).catch((e) => {
          toast.error('Failed to cancel post.');
        });
      }
    })
  }

  renderAggTooltip = ({ active, payload, label }): ReactElement => {
    if (active && payload && payload.length)
      return (
        <div className='shadow rounded p-2' style={{ opacity: 1 }}>
          <strong>{format(label, 'M/yyyy')}</strong>
          <p style={{ color: COLORS.CHART_LINES.PURPLE }}>Total Views: {payload[0]?.value || 0}</p>
          <p style={{ color: COLORS.CHART_LINES.ORANGE }}>Total Interactions: {payload[1]?.value || 0}</p>
          <p style={{ color: COLORS.PRIMARY }}>Total Attendees: {payload[2]?.value || 0}</p>
        </div>
      );

    return null;
  }

  renderFollowTooltip = ({ active, payload, label }): ReactElement => {
    if (active && payload && payload.length)
      return (
        <div className='shadow rounded p-2' style={{ opacity: 1 }}>
          <strong>{format(label, 'M/yyyy')}</strong>
          <p style={{ color: COLORS.PRIMARY }}>Total Follows: {payload[0]?.value || 0}</p>
          <p style={{ color: COLORS.DANGER }}>Total Unfollows: {payload[1]?.value || 0}</p>
        </div>
      );

    return null;
  }

  formatXAxis = (tick: Date) => {
    return format(tick, 'M/yy');
  }

  render() {
    let lastPerfIcon = <GoDash />;
    if (this.state.lastOutperform === Performance.OUTPERFORM)
      lastPerfIcon = <ImArrowUp color={COLORS.PRIMARY} />;
    else if (this.state.lastOutperform === Performance.UNDERPERFORM)
      lastPerfIcon = <ImArrowDown color={COLORS.DANGER} />;

    return (
      <>
        <LoadingIndicator show={this.state.isLoading} />

        {!this.state.isLoading && <PostQueueModal allowFacebook={this.state.hasFacebookToken} allowTwitter={this.state.hasTwitterToken} show={this.state.showPostQueueModal} onClose={this.handleQueueModalClose} />}

        <Row className='mx-0 py-3 mb-4 w-100 d-flex justify-content-center'>
          <Col xl='3' lg='4' xs='12' className='mb-3 mb-lg-0 px-0 px-lg-3'>
            <StatCard onClick={() => this.props.history.push(this.state.nextEvent ? `/Events?event=${this.state.nextEvent.id}` : '/Events')} label='Next Event' value={this.state.nextEvent?.startDate?.toLocaleDateString() || 'None Scheduled'} />
          </Col>
          <Col xl='3' lg='4' xs='12' className='mb-3 mb-lg-0 px-0 px-lg-3'>
            <StatCard onClick={() => this.props.history.push('/Events')} label='Scheduled Events' value={this.state.numberOfScheduled.toString()} />
          </Col>
          <Col xl='3' lg='4' xs='12' className='px-0 px-lg-3'>
            <StatCard label='Last Event vs Average' value={lastPerfIcon} />
          </Col>
        </Row>
        <Row className='mb-4'>
          <Col lg='6' xs='12'>
            <Card className='shadow p-3 mb-4' style={{ height: '500px' }}>
              <h3>Event Performance</h3>
              <ResponsiveContainer>
                <LineChart data={this.state.aggregateStats}>
                  <XAxis dataKey='date' tickFormatter={this.formatXAxis} interval={0} angle={-45} height={50} tickMargin={20} padding={{ right: 30, left: 0 }} />
                  <YAxis width={40} />
                  <Tooltip content={this.renderAggTooltip} />
                  <Line dataKey='views' strokeWidth={3} stroke={COLORS.CHART_LINES.PURPLE} />
                  <Line dataKey='interactions' strokeWidth={3} stroke={COLORS.CHART_LINES.ORANGE} />
                  <Line dataKey='attendees' strokeWidth={3} stroke={COLORS.PRIMARY} />
                </LineChart>
              </ResponsiveContainer>
            </Card>
          </Col>
          <Col lg='6' xs='12'>
            <Card className='shadow p-3' style={{ height: '500px' }}>
              <h3>Followers</h3>
              <ResponsiveContainer>
                <LineChart data={this.state.followStats}>
                  <XAxis dataKey='date' tickFormatter={this.formatXAxis} interval={0} angle={-45} height={50} tickMargin={20} padding={{ right: 30, left: 0 }} />
                  <YAxis width={40} />
                  <Tooltip content={this.renderFollowTooltip} />
                  <Line dataKey='unfollows' strokeWidth={3} stroke={COLORS.DANGER} />
                  <Line dataKey='follows' strokeWidth={3} stroke={COLORS.PRIMARY} />
                </LineChart>
              </ResponsiveContainer>
            </Card>
          </Col>
        </Row>
        <Card className='shadow p-3 position-relative'>
          <h3>Scheduled Posts</h3>
          <Button size='sm' disabled={!this.state.hasFacebookToken && !this.state.hasTwitterToken} onClick={() => this.setState({ showPostQueueModal: true })} className='position-absolute m-3' style={{ top: 0, right: 0 }}><FaPlus size={20} /> New Post</Button>
          {(this.state.hasFacebookToken || this.state.hasTwitterToken) && <ListGroup>
            <ListGroup.Item className='bg-secondary text-white font-weight-bold'>
              <Row>
                <Col lg='2' md='3' xs='6'>PLATFORM</Col>
                <Col lg='2' md='3' xs='6'>POST DATE</Col>
                <Col lg='7' xs='12'>POST</Col>
                <Col xs='1'></Col>
              </Row>
            </ListGroup.Item>
            {this.state.postQueues.map(x => {
              return (
                <ListGroup.Item key={x.id}>
                  <Row>
                    <Col lg='2' md='3' xs='6'>{x.platform === 'twitter' ? <FaTwitter color={COLORS.TWITTER} size={25} /> : <FaFacebook color={COLORS.FACEBOOK} size={25} />}</Col>
                    <Col lg='2' md='3' xs='6'>{format(x.postdate.toDate(), 'MM/dd/yyyy h:mm a')}</Col>
                    <Col lg='7' xs='12'>{x.content}</Col>
                    <Col xs='1' className='text-right'>
                      <Button onClick={() => this.handleDeletePostQueueClick(x)} variant='danger' size='sm'><FaTrashAlt size={15} /></Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              )
            })}
            {this.state.postQueues.length < 1 && (
              <ListGroup.Item className='text-center'>
                No Scheduled Posts
              </ListGroup.Item>
            )}
          </ListGroup>}
          {!this.state.hasFacebookToken && !this.state.hasTwitterToken &&
            <h5>You must link your <Link to='/facebook'>Facebook <FaExternalLinkAlt size={14} /></Link> and/or <Link to='/twitter'>Twitter <FaExternalLinkAlt size={14} /></Link> accounts in order to schedule posts. Navigate to their respective pages to link them.</h5>
          }
        </Card>

      </>
    );
  }
}