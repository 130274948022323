import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  value: string,
  label: string,
  placeholder?: string,
  onTextChange: (value) => void,
  onEnterPressed?: () => void
}

export class Password extends Component<Props> {

  render() {
    return (
      <Form.Group>
        <Form.Label>{this.props.label}</Form.Label>
        <Form.Control onKeyUp={(e: React.KeyboardEvent) => { if (e.key === 'Enter' && this.props.onEnterPressed) this.props.onEnterPressed(); }} type='password' value={this.props.value} placeholder={this.props.placeholder} onChange={(e) => this.props.onTextChange(e.target.value)} />
      </Form.Group>
    );
  }
}