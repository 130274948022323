import { OnApproveData } from '@paypal/paypal-js/types/components/buttons';
import { PayPalButtons } from '@paypal/react-paypal-js';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import AppContext from '../../AppContext';
import { BusinessRegistration, SubscriptionTier, Context } from '../../models';
import PAYPAL_CONFIG from '../../PayPalConfig';

interface Props {
  show: boolean,
  hasReferral: boolean,
  planPrice: number,
  onBack: () => void,
  newBusinessID: string,
  onComplete: (data: OnApproveData) => void
}

interface State extends BusinessRegistration {

}

export default class Payment extends React.Component<Props, State> {
  static contextType?: React.Context<Context> = AppContext;
  
  constructor(props) {
    super(props);

    // this.state = {

    // }
  }

  render() {
    return (
      <div className={!this.props.show ? 'd-none' : ''} style={{ width: 600, maxWidth: '100%' }}>
        <Card className={'shadow m-3 mw-100'}>
          <span className='card-title'>Pay With PayPal</span>
          <div className='p-3'>
            <PayPalButtons
              createSubscription={(data, actions) => actions.subscription.create({
                plan_id: PAYPAL_CONFIG.subscriptionPlanID,
                auto_renewal: true,
                custom_id: this.props.newBusinessID,
                plan: this.props.hasReferral ? {
                  billing_cycles: [
                    {
                      sequence: 1,
                      total_cycles: 12,
                      pricing_scheme: {
                        fixed_price: {
                          currency_code: 'USD',
                          value: '0.00'
                        }
                      }
                    },
                    {
                      sequence: 2,
                      total_cycles: 0,
                      pricing_scheme: {
                        fixed_price: {
                          currency_code: 'USD',
                          value: this.props.planPrice.toFixed(2)
                        }
                      }
                    }
                  ]
                } : undefined
              })}
              onError={(err) => {
                toast.error('Payment not complete.');
              }}
              onApprove={(data, actions) => {
                this.props.onComplete(data);
                return Promise.resolve();
              }}
              style={{ color: 'white' }}
            />
          </div>
        </Card>
        {!this.context?.user?.businessid && <Row className='mx-1'>
          <Col>
            <Button onClick={this.props.onBack} className='shadow' block variant='light'><FaChevronLeft /> Back</Button>
          </Col>
        </Row>}
      </div>
    );
  }
}