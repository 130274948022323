import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

interface Props {
  value: object,
  label: string,
  placeholder?: string,
  options: Array<object>,
  disabled?: boolean,
  onChange: (option) => void
}

export class Dropdown extends Component<Props> {

  render() {
    return (
      <Form.Group>
        <Form.Label>{this.props.label}</Form.Label>
        <Select
          isDisabled={this.props.disabled}
          classNamePrefix='dropdown'
          value={this.props.value}
          options={this.props.options}
          onChange={this.props.onChange}
        />
      </Form.Group>
    );
  }
}