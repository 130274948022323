import * as firebase from 'firebase';
import { ModelBase, Event, PostQueue } from '../models';
import moment from 'moment';

export class EventProvider {
    static getEvents(businesssid: string): Promise<Event[]> {
        return firebase.firestore().collection('businesses').doc(businesssid).collection('events').where('startDate', '>=', moment(new Date()).subtract(1, 'year').toDate()).get().then((docs) => {
            if (docs.empty) {
                return [];
            } else {
                let events: Event[] = [];

                docs.forEach(doc => {
                    const data = doc.data();
                    events.push({ id: doc.id, ...data, startDate: data.startDate.toDate(), endDate: data.endDate.toDate() } as Event);
                });

                return events;
            }
        });
    }

    static saveUpdateEvent(businessid: string, event: Event, createChat = false): Promise<Event> {
        const createEvent = firebase.functions().httpsCallable('createEvent');
        return createEvent(JSON.stringify({ businessid: businessid, event: event, createChat: createChat })).then((result) => {
            let event = JSON.parse(result.data);
            event.startDate = moment(event.startDate).toDate();
            event.endDate = moment(event.endDate).toDate();
            if (typeof event.chatid === 'object'){
                delete event.chatid;
            }
            return event as Event;
        }).catch((err) => {
            event.success = false;
            event.message = 'Failed to create event.';
            return event;
        });
    }

    static deleteEvent(businessid: string, eventid: string): Promise<ModelBase> {
        return firebase.firestore().collection('businesses').doc(businessid).collection('events').doc(eventid).delete().then(() => {
            return { success: true } as ModelBase;
        }).catch((err) => {
            return { success: false, message: 'Failed to delete event.' } as ModelBase;
        });
    }

    static getEventPostQueue(businessid: string): Promise<PostQueue[]> {
        return firebase.firestore().collection('postqueue').where('businessid', '==', businessid).get().then((docs) => {
            if (docs.empty)
                return [];
            
            return docs.docs.map(doc => ({ id: doc.id, ...doc.data() } as PostQueue));
        });
    }

    static deletePostQueue(postqueueid: string): Promise<void> {
        const postQueue = firebase.functions().httpsCallable('createDeleteEventPostQueue');
        return postQueue(JSON.stringify({ postqueueid, delete: true })).then(() => {
            return;
        });
    }

    static saveUpdatePostQueue(businessid: string, content: string, eventid: string, platform: 'facebook' | 'twitter', includelink: boolean, postqueueid: string = null, posthoursbefore: number = 0, postdate: Date = null): Promise<PostQueue> {
        const postQueue = firebase.functions().httpsCallable('createDeleteEventPostQueue');
        return postQueue(JSON.stringify({ businessid, content, eventid, platform, posthoursbefore, postqueueid, includelink, postdate })).then((result) => {
            let data = JSON.parse(result.data);
            return { ...data, postdate: firebase.firestore.Timestamp.fromDate(new Date(data.postdate)) } as PostQueue;
        });
    }
}