import React from 'react';
import { Card } from 'react-bootstrap';

interface Props {
  onClick?: () => void,
  label: string;
  value: string | JSX.Element;
}

const StatCard = (props: Props) => {
  return (
    <Card onClick={props.onClick} className='shadow p-3' style={{ height: '150px', cursor: props.onClick ? 'pointer' : null }}>
      <strong>{props.label}</strong>
      <div className='d-flex align-items-center h-100'>
        <strong className='w-100 text-center' style={{ fontSize: '30px'}}>{props.value}</strong>
      </div>
    </Card>
  );
}

export default StatCard;