import React, { Component } from "react";
import { LogoTop } from "../../shared/LogoTop";
import { Row, Col, Jumbotron, Container, Button, Card, Spinner } from "react-bootstrap";
import logo from '../../assets/images/icon.png';
import eventsImage from '../../assets/images/events.png';
import friendsImage from '../../assets/images/friends.png';
import statsImage from '../../assets/images/stats.png';
import socialImage from '../../assets/images/socialmedia.png';
import graphsImage from '../../assets/images/graphs.png';
import { RouteComponentProps } from "react-router-dom";
import AppContext from "../../AppContext";
import COLORS from "../../shared/static/Colors";
import { FaAppStore, FaCheckSquare, FaGooglePlay } from "react-icons/fa";
import { Footer } from "../../shared/Footer";
import { PayPalProvider } from "../../providers/PayPalProvider";

interface Props extends RouteComponentProps { }

interface State {
  currentPrice: number,
  loadingPrice: boolean
}

export class BusinessHome extends Component<Props, State> {
  static contextType = AppContext;

  constructor(props, state) {
    super(props);

    this.state = {
      currentPrice: 100,
      loadingPrice: true
    };
  }

  componentDidMount() {
      PayPalProvider.getPlanPrice().then((result) => {
        if (result.success) {
          this.setState({ currentPrice: result.price });
        }
      }).finally(() => {
        this.setState({ loadingPrice: false });
      });
  }

  handleGetStartedClick = () => {
    this.props.history.push('/register');
  }

  render() {
    return (
      <div style={{ backgroundColor: '#f7f7f7' }}>
        <LogoTop showLogin user={this.context.user} history={this.props.history} />
        <div style={{ backgroundColor: COLORS.WHITE }} className='shadow' >
          <Container className='pb-1 pb-lg-0'>
            <Jumbotron style={{ backgroundColor: COLORS.WHITE, height: '300px', paddingTop: 0 }} className='pb-0 mb-0'>
              <Row>
                <Col>
                  <p style={{ fontSize: 40 }}>
                    Local online advertising that works.
                  </p>
                  <Button className='shadow' onClick={this.handleGetStartedClick} >Get Started</Button>
                </Col>
                <Col xs='5' className='d-lg-flex d-none justify-content-end'>
                  <img src={graphsImage} height={300} />
                </Col>
              </Row>
            </Jumbotron>
          </Container>
        </div>
        <div>
          <Row noGutters className='mb-3 mt-4 mt-lg-0 flex-lg-row-reverse info-row' >
            <Col lg xs='12' className='d-flex rounded align-items-center justify-content-center ml-lg-4'>
                <Card className='shadow' style={{ width: '300px', maxWidth: '100%' }}>
                  <div className='w-100 text-center rounded' style={{ backgroundColor: COLORS.PRIMARY }}>
                    <img height={70} className='mx-auto' src={logo} />
                  </div>
                  <div className='p-2 font-weight-bold'>
                    <Row className='border-bottom' style={{ height: '30px' }}>
                      <Col xs='10'>
                        PartyMe Portal Access
                      </Col>
                      <Col className='text-right'>
                        <FaCheckSquare size={20} color={COLORS.PRIMARY} />
                      </Col>
                    </Row>
                    <Row className='border-bottom' style={{ height: '30px' }}>
                      <Col xs='10'>
                        Event Statistics Reporting
                      </Col>
                      <Col className='text-right'>
                        <FaCheckSquare size={20} color={COLORS.PRIMARY} />
                      </Col>
                    </Row>
                    <Row className='border-bottom' style={{ height: '30px' }}>
                      <Col xs='10'>
                        Troublshooting Support
                      </Col>
                      <Col className='text-right'>
                        <FaCheckSquare size={20} color={COLORS.PRIMARY} />
                      </Col>
                    </Row>
                    <Row className='border-bottom' style={{ height: '30px' }}>
                      <Col xs='10'>
                        Facebook Integration
                      </Col>
                      <Col className='text-right'>
                        <FaCheckSquare size={20} color={COLORS.PRIMARY} />
                      </Col>
                    </Row>
                    <Row className='border-bottom' style={{ height: '30px' }}>
                      <Col xs='10'>
                        Twitter Integration
                      </Col>
                      <Col className='text-right'>
                        <FaCheckSquare size={20} color={COLORS.PRIMARY} />
                      </Col>
                    </Row>
                    <Row className='border-bottom' style={{ height: '30px' }}>
                      <Col xs='10'>
                        1 Month Free Trial
                      </Col>
                      <Col className='text-right'>
                        <FaCheckSquare size={20} color={COLORS.PRIMARY} />
                      </Col>
                    </Row>
                    <p className='text-center w-100' style={{ fontSize: '40px', paddingLeft: '20px' }}>{this.state.loadingPrice ? <Spinner style={{ fontSize: 22 }} as='span' animation='border' /> : `$${this.state.currentPrice}`} <span style={{ fontSize: '10px', color: COLORS.GRAY }}>/mo</span></p>
                  </div>
                </Card>
            </Col>
            <Col lg xs='12' className='d-flex align-items-center justify-content-center text-center mr-lg-4'>
              <div style={{ width: '500px' }}>
                <h3>Simple, Affordable Pricing</h3>
                <span style={{ fontSize: '20px' }}>
                  One plan, one price. All features of PartyMe are available as a subscription for $100 /mo. All payments are handled securely through PayPal and you can cancel at any time.
                </span>
              </div>
            </Col>
          </Row>
          <Row noGutters className='my-3 info-row bg-white' >
            <Col lg xs='12' className='d-flex align-items-center justify-content-center mr-lg-4'>
              <img src={eventsImage} width={400} style={{ maxHeight: '96%' }} className='rounded shadow mt-3 mb-1 mb-lg-0' />
            </Col>
            <Col lg xs='12' className='d-flex align-items-center text-center justify-content-center ml-lg-4'>
              <div style={{ width: '500px' }}>
                <h3>Promote Your Business</h3>
                <span style={{ fontSize: '20px' }}>
                  Reach an audience that matters. Stand out from the crowd and build interest in the events your business hosts. Whether it's a New Year's party, karaoke, or half priced beer - PartyMe can increase visibility and attendance.
                </span>
              </div>
            </Col>
          </Row>
          <Row noGutters className='my-3 flex-lg-row-reverse info-row' >
            <Col lg xs='12' className='d-flex align-items-center justify-content-center ml-lg-4'>
              <img src={statsImage} width={300} style={{ maxHeight: '96%' }} className='rounded shadow mt-3 mb-1 mb-lg-0' />
            </Col>
            <Col lg xs='12' className='d-flex align-items-center justify-content-center text-center mr-lg-4'>
              <div style={{ width: '500px' }}>
                <h3>Get Valuable Statistics</h3>
                <span style={{ fontSize: '20px' }}>
                  The dashboard allows you to see how your events are doing over time. Views, interactions, and attendence are all reported.
                </span>
              </div>
            </Col>
          </Row>
          <Row noGutters className='my-3 info-row bg-white' >
            <Col lg xs='12' className='d-flex align-items-center justify-content-center mr-lg-4'>
              <img src={socialImage} width={400}  style={{ maxHeight: '96%' }} className='rounded shadow mt-3 mb-1 mb-lg-0' />
            </Col>
            <Col lg xs='12' className='d-flex align-items-center text-center justify-content-center ml-lg-4'>
              <div style={{ width: '500px' }}>
                <h3>Social Media Made Easy</h3>
                <span style={{ fontSize: '20px' }}>
                  Link PartyMe with your business' Facebook and Twitter accounts to automatically schedule posts for your events. See recent posts and interact with your following directly through PartyMe.
                </span>
              </div>
            </Col>
          </Row>
          {/* <Row className='my-3 flex-lg-row-reverse'>
            <Col md xs='12' className='d-flex align-items-center justify-content-center justify-content-lg-start ml-lg-4'>
              <img src={chatImage} width={300} className='rounded shadow mt-3 mb-1 mb-lg-0' />
            </Col>
            <Col md xs='12' className='d-flex align-items-center justify-content-lg-end justify-content-center text-lg-right text-center mr-lg-4'>
              <div style={{ width: '350px' }}>
                <h3>Chat in Groups or Privately</h3>
                <span>
                  Just like any other chat app, you can create groups of friends to chat with.
                  You can also speak to them directly through private chats.
              </span>
              </div>
            </Col>
          </Row> */}
        </div>
        <Footer />
      </div>
    );
  }
}