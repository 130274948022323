import React, { Component } from "react";
import { LogoTop } from "../../shared/LogoTop";
import { Row, Col, Jumbotron, Container, Button } from "react-bootstrap";
import eventImage from '../../assets/images/event.png';
import eventsImage from '../../assets/images/events.png';
import friendsImage from '../../assets/images/friends.png';
import chatImage from '../../assets/images/chat.png';
import partiesImage from '../../assets/images/parties.png';
import phoneImage from '../../assets/images/phone.png';
import { RouteComponentProps } from "react-router-dom";
import AppContext from "../../AppContext";
import COLORS from "../../shared/static/Colors";
import { FaAppStore, FaGooglePlay } from "react-icons/fa";

interface Props extends RouteComponentProps { }

interface State { }

export class Support extends Component<Props, State> {
  static contextType = AppContext;

  render() {
    return (
      <>
        <LogoTop showLogin user={this.context.user} history={this.props.history} />
        <Container>
          <h3>Support</h3>
          <p>Please review the FAQs below. For additional assistance please send an email to <a href='mailto:support@partyme.io'>support@partyme.io</a>.</p>
          <br/>
          <br/>
          <h5>What does it mean for the app to be in beta?</h5>
          <p>
            BETA stands for "Break it Early Test Application". PartyMe is currently in a testing phase where we try to iron out all of the bugs before public availability.
            Some features of the app may not be available or may work incorrectly. There is a small (extremely unlikely) chance that all data will be wiped before the full release.
            We limit the number of users that have access to the app while in beta.
          </p>
          <br/>
          <br/>
          <h5>Why are there no results for "Parties Nearby"?</h5>
          <p>
            This goes back to the app being in beta. Parties shown on the home page will be posted by businesses in the area. The business portion of the app is not currently available.

          </p>
          <br/>
          <br/>
          <h5>How can we provide feedback or report errors?</h5>
          <p>
            The app will automatically report most errors to us. We still recommend reaching out for any issues or suggestions you may have. If you are on iOS, you can provide feedback through the Testflight app simply by taking a screenshot.
            You can also send us an email at <a href='mailto:feedback@partyme.io'>feedback@partyme.io</a>.
          </p>
          <br/>
          <br/>
          <h5>How can I post parties for my business?</h5>
          <p>
            The business portion of the project is still under development. You can preemptively apply for a business account by visiting the <a href='/apply'>Application page</a>. This application process may change in the future.
          </p>
          <br/>
        </Container>
      </>
    );
  }
}