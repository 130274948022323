import React, { Component } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  value: string,
  label: string,
  error?: boolean,
  placeholder?: string,
  description?: string,
  disabled?: boolean,
  onTextChange: (value) => void,
  onEnterPressed?: () => void
}

export class Textbox extends Component<Props> {

  render() {
    return (
      <Form.Group>
        <Form.Label>{this.props.label}</Form.Label>
        <Form.Control onKeyUp={(e: React.KeyboardEvent) => { if (e.key === 'Enter' && this.props.onEnterPressed) this.props.onEnterPressed(); }} disabled={this.props.disabled} className={this.props.error ? 'error' : ''} type='text' value={this.props.value} placeholder={this.props.placeholder} onChange={(e) => this.props.onTextChange(e.target.value)} />
        {this.props.description ? <Form.Text>{this.props.description}</Form.Text> : null}
      </Form.Group>
    );
  }
}