import React from 'react';
import { Spinner } from 'react-bootstrap';

interface Props {
  show: boolean
}

const ButtonSpinner = (props: Props) => {
  if (!props.show)
    return null;

  return (
    <Spinner as='span' animation='border' size='sm' />
  );
}

export default ButtonSpinner;