import * as React from "react"
import * as ReactDOM from "react-dom"
import { Modal, Button } from "react-bootstrap"
import { FaCheck, FaTimes } from "react-icons/fa"
import { CheckmarkIcon, TimesIcon } from "./static/Icons";
import COLORS from "./static/Colors";

type variant = "link" | "secondary" | "primary" | "success" | "danger" | "warning" | "info" | "dark" | "light" | "outline-primary" | "outline-secondary" | "outline-success" | "outline-danger" | "outline-warning" | "outline-info" | "outline-dark" | "outline-light";

const confirmRoot = document.createElement("div")
const body = document.querySelector("body")
body.appendChild(confirmRoot)

export class ConfirmationAlert {

  static confirm(title: string, prompt: string | Element | JSX.Element, variant: variant = 'primary'): Promise<boolean> {
    let confirmRoot = document.createElement('div');
    let body = document.querySelector('body');
    body.appendChild(confirmRoot);

    return new Promise<boolean>((resolve) => {
      ReactDOM.render(<ConfirmationContent resolve={resolve} title={title} prompt={prompt} variant={variant} />, confirmRoot);
    }).then((value) => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      return value;
    });
  }

  static alert(title: string, prompt: string | Element | JSX.Element, variant: variant = 'primary'): void {
    let confirmRoot = document.createElement('div');
    let body = document.querySelector('body');
    body.appendChild(confirmRoot);

    new Promise<boolean>((resolve) => {
      ReactDOM.render(<ConfirmationContent resolve={resolve} title={title} prompt={prompt} variant={variant} isAlert />, confirmRoot);
    }).then((value) => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      return value;
    });
  }

  static popup(prompt: string | Element | JSX.Element, variant: 'primary' | 'danger'): void {
    let confirmRoot = document.createElement('div');
    let body = document.querySelector('body');
    body.appendChild(confirmRoot);

    new Promise<boolean>((resolve) => {
      ReactDOM.render(<ConfirmationContent resolve={resolve} prompt={prompt} isPopup variant={variant} />, confirmRoot);
    }).then((value) => {
      ReactDOM.unmountComponentAtNode(confirmRoot);
      return value;
    });
  }
}

interface Props {
  resolve: (boolean) => void,
  title?: string,
  prompt?: string | Element | JSX.Element,
  variant?: variant,
  isAlert?: boolean,
  isPopup?: boolean
}

class ConfirmationContent extends React.Component<Props> {
  render() {
    return (
      <Modal size={this.props.isPopup ? 'sm' : undefined} style={{ zIndex: 1055 }} backdropClassName='confirmation-backdrop' centered show onHide={() => this.props.resolve(false)} aria-labelledby='contained-modal-title-vcenter'>
        {!this.props.isPopup &&
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter' style={{ fontWeight: 'bold' }}>{this.props.title}</Modal.Title>
          </Modal.Header>
        }

        <Modal.Body>
          {this.props.isPopup && this.props.variant === 'primary' && <CheckmarkIcon color={COLORS[this.props.variant.toUpperCase()]} />}
          {this.props.isPopup && this.props.variant === 'danger' && <TimesIcon color={COLORS[this.props.variant.toUpperCase()]} />}
          {this.props.prompt}
        </Modal.Body>

        {!this.props.isPopup &&
          <Modal.Footer>
            {!this.props.isAlert && <>
              <Button variant='secondary' onClick={() => this.props.resolve(false)}><FaTimes size={20} /> No</Button>
              <Button variant={this.props.variant || 'primary'} onClick={() => this.props.resolve(true)}><FaCheck size={20} /> Yes</Button>
            </>}
            {this.props.isAlert &&
              <Button variant={this.props.variant || 'primary'} onClick={() => this.props.resolve(true)}>Close</Button>
            }
          </Modal.Footer>
        }
      </Modal>
    );
  }
}