import React, { Component } from 'react';
import { Login } from './pages/login/Login';
import { BrowserRouter as Router, Switch, Route, withRouter, RouteComponentProps } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Events } from './pages/events/Events';
import { Business, User } from './models';
import * as firebase from 'firebase';
import { Layout } from './layout/Layout';
import FIREBASE_CONFIG from './FirebaseConfig';
import 'spinkit/spinkit.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { AuthProvider } from './providers/AuthProvider';
import { Register } from './pages/register/Register';
import { Facebook } from './pages/facebook/Facebook';
import { Instagram } from './pages/instagram/Instagram';
import { Settings } from './pages/settings/Settings';
import { Error } from './pages/error/Error';
import { Twitter } from './pages/twitter/Twitter';
import { Home } from './pages/home/Home';
import { Support } from './pages/support/Support';
import { NotFound } from './pages/notfound/NotFound';
import { Feedback } from './pages/feedback/Feedback';
import { BusinessHome } from './pages/businesshome/BusinessHome';
import { DeepLink } from './pages/deeplink/DeepLink';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PAYPAL_CONFIG from './PayPalConfig';
// import ManageSubscription from './pages/managesubscription/ManageSubscription';
import { UserProvider } from './providers/UserProvider';
import { InactiveSubscription } from './pages/inactivesubscription/InactiveSubscription';
import { Subscription } from 'rxjs';
import { LoadingIndicator } from './shared/LoadingIndicator';

interface Props extends RouteComponentProps { }

interface State {
  isLoading: boolean,
  user: User,
  business: Business,
  authSub: firebase.Unsubscribe,
  navSub: () => void,
  businessSub: Subscription
}

declare global {
  interface Window {
    FB: any;
  }
}

const authRouteExceptions = [
  'login',
  'support',
  'register',
  'inactivesubscription',
  '/app/',
  'business'
];

// TOGGLE THIS TO DETERMINE WHICH FIREBASE & PAYPAL ENVIRONMENT TO HIT
// FIREBASE & PAYPAL CONFIGS ALWAYS HIT DEV ON LOCAL HOST
export const debugMode: boolean = window.location.hostname === 'localhost';

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    if (!firebase.apps.length)
      firebase.initializeApp(FIREBASE_CONFIG);

    this.state = {
      isLoading: true,
      user: null,
      business: null,
      authSub: null,
      navSub: null,
      businessSub: null
    }
  }

  async componentDidMount() {
    // This will always make sure they should have access
    const navUnsub = this.props.history.listen((location, action) => {
      //Only need to redirect if they are trying to get somewhere important
      this.checkForRedirect();
    });

    const unsub = firebase.auth().onAuthStateChanged(async (user) => {
      if (user && !user.isAnonymous) {
        firebase.firestore().collection('businessusers').doc(user.uid).get().then(async (doc) => {
          if (doc.exists) {
            const oUser: User = { id: doc.id, ...doc.data() } as User;
            // const business: Business = await UserProvider.getBusiness(oUser.businessid);


            this.setState({ user: oUser });
            this.initBusinessSub(oUser.businessid);
          } else {
            toast.error('Account used to log in is not tied to a business account.');
            AuthProvider.signOut().then(() => {
              this.setState({ isLoading: false });
            });
          }
        }).catch((err) => {
          console.log(err);
          this.setState({ isLoading: false });
          if (!window.location.pathname.toLowerCase().includes('error'))
            window.location.pathname = '/error';
        });
      } else {
        setTimeout(() => {
          this.setState({ isLoading: false, user: null, business: null }, () => {
            this.checkForRedirect();
          });
        }, 500);
      }
    });
    this.setState({ authSub: unsub, navSub: navUnsub });
  }

  componentWillUnmount() {
    if (this.state.authSub)
      this.state.authSub();
    if (this.state.navSub)
      this.state.navSub();
    if (this.state.businessSub)
      this.state.businessSub.unsubscribe();
  }

  initBusinessSub = (businessid: string) => {
    const businessSub: Subscription = UserProvider.getBusinessSubscription(businessid).subscribe((business: Business) => {
      this.setState({ business, isLoading: false }, () => {
        this.checkForRedirect();
      });
    });
    this.setState({ businessSub });
  }

  checkForRedirect = () => {
    let path = window.location.pathname.toLowerCase();

    if (this.isLoginRequired()) {
      if (this.state.user) {
        if (this.state.business.activeuntil.toDate() < new Date()) {
          if (this.state.business.subscriptionid) {
            if (!path.includes('inactivesubscription'))
              this.props.history.push('/inactivesubscription');
          } else {
            toast.warn('Your subscription was cancelled, and has run out. In order to use PartyMe, set up a new subscription.', { autoClose: false });
            if (!path.includes('register'))
              this.props.history.push('/register');
          }
        }
      } else {
        this.props.history.push('/login');
      }
    } else {
      if (this.state.user && path.includes('login'))
        this.props.history.push('/dashboard');
      else if (this.state.business && this.state.business.subscriptionid && path.includes('register')) {
        toast.warn('This account already has an active subscription. To register a new account, please log out first.', { autoClose: false });
        this.props.history.push('/dashboard');
      }
    }
  }

  isLoginRequired = () => {
    if (window.location.pathname == '/')
      return false;

    let required = true;
    authRouteExceptions.forEach(x => {
      if (window.location.pathname.toLowerCase().includes(x)) {
        required = false;
      }
    });

    return required;
  }

  render() {
    if (this.state.isLoading)
      return <LoadingIndicator show />;

    return (
      <PayPalScriptProvider options={{ "client-id": PAYPAL_CONFIG.clientID, vault: true, intent: 'subscription', debug: false }} >
        <ToastContainer position='top-right' newestOnTop closeOnClick></ToastContainer>
        <Switch>

          <Route exact path="/" component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading}  >
                <Home {...props} />
              </Layout>
            );
          }} />

          <Route exact path="/Business" component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} >
                <BusinessHome {...props} />
              </Layout>
            );
          }} />Ï

          <Route exact path="/Dashboard" component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={true} pageTitle='Dashboard' >
                <Dashboard {...props} />
              </Layout>
            );
          }} />

          <Route exact path="/Login" component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} >
                <Login {...props} />
              </Layout>
            );
          }} />

          <Route exact path="/Events" component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={true} pageTitle='Events' >
                <Events {...props} />
              </Layout>
            );
          }} />

          <Route exact path="/Facebook" component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={true} pageTitle='Facebook' >
                <Facebook {...props} />
              </Layout>
            );
          }} />

          {/* <Route exact path="/Instagram" component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={true} >
                <Instagram {...props} />
              </Layout>
            );
          }} /> */}

          <Route exact path="/Twitter" component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={true} pageTitle='Twitter' >
                <Twitter {...props} />
              </Layout>
            );
          }} />

          <Route exact path="/Register" component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={false} >
                <Register {...props} />
              </Layout>
            );
          }} />

          <Route exact path="/InactiveSubscription" component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={false} >
                <InactiveSubscription {...props} />
              </Layout>
            );
          }} />

          <Route exact path="/Support" component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={false} >
                <Support {...props} />
              </Layout>
            );
          }} />

          <Route exact path='/Events' component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={true} pageTitle='Events' >
                <Events {...props} />
              </Layout>
            );
          }} />

          <Route exact path='/Feedback' component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={true} pageTitle='Feedback' >
                <Feedback {...props} />
              </Layout>
            );
          }} />

          {this.state.user && this.state.user.isAdmin ? <Route exact path='/Settings' component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={true} pageTitle='Settings' >
                <Settings {...props} />
              </Layout>
            );
          }} /> : null}

          {/* {this.state.user && this.state.user.isAdmin ? <Route exact path='/ManageSubscription' component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={true} pageTitle='Manage Subscription' >
                <ManageSubscription {...props} />
              </Layout>
            );
          }} /> : null} */}

          <Route exact path='/app/:referencetype/:referenceid' component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={false} >
                <DeepLink {...props} />
              </Layout>
            );
          }} />

          <Route exact path='/Error' component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={false} >
                <Error {...props} />
              </Layout>
            );
          }} />

          <Route component={(props) => {
            return (
              <Layout {...props} user={this.state.user} appLoading={this.state.isLoading} showNav={true} pageTitle='404' >
                <NotFound {...props} />
              </Layout>
            );
          }} />
        </Switch>
      </PayPalScriptProvider>
    );
  }
}

export default withRouter(App);