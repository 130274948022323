import { debugMode } from "./App";

const FIREBASE_CONFIG = window.location.hostname === 'localhost' || debugMode ? {
  apiKey: "AIzaSyCNB6YNOhrSjPhSgiNdeT0mZS8kdfWDoKo",
  authDomain: "partyme-dev.firebaseapp.com",
  projectId: "partyme-dev",
  storageBucket: "partyme-dev.appspot.com",
  messagingSenderId: "344762165207",
  appId: "1:344762165207:web:0ef76d896860cbc567cd92"
} : {
  apiKey: "AIzaSyC7RF4Guf0TxoH4wwGiWyN7-8Q9gkgKzak",
  authDomain: "partyme-185321.firebaseapp.com",
  databaseURL: "https://partyme-185321.firebaseio.com",
  projectId: "partyme-185321",
  storageBucket: "partyme-185321.appspot.com",
  messagingSenderId: "647119470298",
  appId: "1:647119470298:web:ff71bb23f8e5f64c89efe3"
}

export default FIREBASE_CONFIG;