import * as firebase from 'firebase';

export class AuthProvider {
  static sendPasswordResetEmail(email: string) {
    firebase.auth().sendPasswordResetEmail(email);
  }

  static loginWithEmailPassword(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  static resetPassword(email: string): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  static signOut(): Promise<any> {
    return firebase.auth().signOut();
  }

  static getUser(): firebase.User {
    return firebase.auth().currentUser;
  }
}