import React, { Component } from 'react'

interface Props {
  message?: string,
  background?: string,
  show: boolean
};

export class LoadingIndicator extends Component<Props> {
  render() {
    if (!this.props.show)
      return null;

    return (
      <div className='position-absolute w-100 h-100' style={{ zIndex: 9, backgroundColor: 'rgba(235,235,235,1)' }}>
        <div className='sk-fold'>
          <div className='sk-fold-cube'></div>
          <div className='sk-fold-cube'></div>
          <div className='sk-fold-cube'></div>
          <div className='sk-fold-cube'></div>
        </div>
        {this.props.message ? <span className='loading-message'>{this.props.message}</span> : null}
      </div>
    );
  }
}